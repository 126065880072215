import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Resource } from '@devexpress/dx-react-scheduler';
import { Box, colors as materialColors, Grid, MenuItem } from '@material-ui/core';
import { sortBy } from 'lodash';
import { useStyles } from 'src/components/DashboardMedspaAdmin/Calendar/index.styles';
// eslint-disable-next-line max-len
import { AppointmentStatusEnum } from 'src/components/DashboardMedspaAdmin/Calendar/MedspaCalendar/AppointmentStatusEnum';
// eslint-disable-next-line max-len
import { getGFEStatusIconComponent } from 'src/components/DashboardMedspaAdmin/Calendar/MedspaCalendar/gfeStatusUtils';
import {
  VIEW_NAME_DAY,
  VIEW_NAME_WEEK,
  VIEW_NAME_WEEK_PROVIDER,
} from 'src/components/DashboardMedspaAdmin/Calendar/indexV2';
import { RootState } from '../../../rematch';
import { IMedspaLocation } from '../../../interfaces/ILocation';
import { useMedspaLocations } from '../../../hooks/queries/medspaAdmins/useMedspaLocations';
import { IAppointmentType } from '../../../interfaces/acuity/acuity.interface';
import { useGetMedspaAppointments } from '../../../hooks/medspaCalendar/useAppointments';
import { useMedspaAppointmentTypes } from '../../../hooks/queries/medspaAdmins/useMedspaAppointmentTypes';
import { ExtendedAppointmentModel } from '../../../components/DashboardMedspaAdmin/Calendar/MedspaCalendar/grouping';
import { BookingTypeEnum } from '../../../components/DashboardMedspaAdmin/Calendar/MedspaCalendar/EnumBookingType';
import { MedspaCalendarGFEStatusEnum } from '../../../constants/general.constants';
import { IWorkingHour } from '../../../services/medspaAdmins/MedspaCalendar';
import Page from '../../../components/common/Page';
import { EHRSelect } from '../../../components/ui/v1';
import MedspaCalendar from '../../../components/DashboardMedspaAdmin/Calendar/MedspaCalendar';
import { usePractitionerInfo } from '../../../hooks/queries/usePractitioners';

const MedspaPractitionerCalendar = () => {
  const { userGroupId, name: userName } = useSelector(({ auth }: RootState) => auth);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [selectedMedspaLocation, setSelectedMedspaLocation] = useState<IMedspaLocation>();
  const { userId: practitionerId } = useSelector(({ auth }: any) => auth);
  const { practitionerInfo: provider } = usePractitionerInfo(practitionerId);
  const [selectedAppTypeId, setSelectedAppTypeId] = useState<string | number>('');
  const [selectedGFEStatus, setSelectedGFEStatus] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [currentViewName, setCurrentViewName] = useState<string>(VIEW_NAME_DAY);
  const {
    data: { medspaLocations = [] },
    isLoading: isLoadingMedspaLocations,
  } = useMedspaLocations(userGroupId);
  const classes = useStyles();

  const { data: locationAppTypes = [], isLoading: isLoadingTypes } = useMedspaAppointmentTypes(userGroupId, {
    medspaLocationId: selectedMedspaLocation?.id,
    practitionerId,
  });

  const availableMedspaLocations = useMemo(() => {
    if (medspaLocations.length) {
      return sortBy(
        medspaLocations.filter((medspaLocation: IMedspaLocation) => {
          const practitionerFound = medspaLocation.practitioners.find(
            (practitioner) => practitioner.id === practitionerId
          );

          return practitionerFound && practitionerFound.activeForScheduling && medspaLocation.active;
        }),
        (medspaLocation: IMedspaLocation) => medspaLocation.name
      );
    }
    return [];
  }, [medspaLocations, practitionerId]);

  useEffect(() => {
    if (!selectedMedspaLocation && availableMedspaLocations.length > 0 && !isLoadingMedspaLocations) {
      setSelectedMedspaLocation(availableMedspaLocations[0]);
    }
  }, [availableMedspaLocations, selectedMedspaLocation, isLoadingMedspaLocations]);

  const enabledAppTypes = useMemo(
    () => locationAppTypes.filter((appType: IAppointmentType) => !appType.hidden),
    [locationAppTypes]
  );

  const {
    data: medspaAppointments,
    isLoading: isLoadingMedspaAppointments,
    isFetching: isFetchingMedspaAppointments,
  } = useGetMedspaAppointments(
    selectedMedspaLocation?.id,
    moment(currentDate).format('YYYY-MM-DD'),
    currentViewName === VIEW_NAME_WEEK_PROVIDER ? VIEW_NAME_WEEK : currentViewName
  );

  const isLoading = isLoadingMedspaLocations || isLoadingTypes || isLoadingMedspaAppointments;

  const providerResources = useMemo<Resource[]>(
    () => [
      {
        fieldName: 'practitionerId',
        title: 'Provider',
        instances: [
          {
            id: practitionerId,
            text: userName,
            color: materialColors.amber,
          },
        ],
      },
    ],
    [practitionerId, userName]
  );

  const appointments = useMemo(
    () =>
      (medspaAppointments?.calendars || []).flatMap((data) => {
        // @ts-ignore
        const results: ExtendedAppointmentModel[] = data.appointments.map((appointment) => {
          const bookingType = BookingTypeEnum.APPOINTMENT;
          const { practitionerId: providerId, customerLastPhotoRequestStatusMap = {} } = data;
          return {
            title: 'Appointment',
            practitionerId: providerId,
            startDate: appointment.startTime,
            endDate: appointment.endTime,
            addOnDuration: appointment.addOnDuration,
            allDay: appointment.allDay || false,
            id: appointment.id,
            bookingType,
            status: appointment.status as AppointmentStatusEnum,
            notes: appointment.notes,
            gfeStatus: appointment.gfeStatus as MedspaCalendarGFEStatusEnum,
            appointmentTypeId: appointment.appointmentTypeId,
            appointmentTypeName: appointment.appointmentTypeName,
            patientName: appointment.patientName,
            patientEmail: appointment.patientEmail,
            addOns: appointment.addOns,
            serviceGroups: appointment.serviceGroups,
            services: appointment.services,
            lastPhotoRequestStatus: customerLastPhotoRequestStatusMap
              ? customerLastPhotoRequestStatusMap[appointment.customerId]
              : null,
          };
        });
        return results;
      }),
    [medspaAppointments]
  );

  // eslint-disable-next-line max-len
  const blockOffs = useMemo(
    () =>
      (medspaAppointments?.calendars || []).flatMap((data) => {
        // @ts-ignore
        const results: ExtendedAppointmentModel[] = data.blockOffs.map((blockOff) => {
          const bookingType = BookingTypeEnum.BLOCK_OFF_HOUR;
          const { practitionerId: providerId } = data;
          return {
            title: 'Block Off',
            practitionerId: providerId,
            startDate: blockOff.start,
            endDate: blockOff.end,
            allDay: false,
            id: blockOff.id,
            bookingType,
            notes: blockOff.notes,
            gfeStatus: null,
            appointmentTypeId: 0,
            appointmentTypeName: null,
            patientName: null,
            patientEmail: null,
          };
        });
        return results;
      }),
    [medspaAppointments]
  );

  const workingHoursMap = useMemo(
    () =>
      (medspaAppointments?.calendars || []).reduce<{ [key: number]: IWorkingHour[] }>((acc, data) => {
        const { practitionerId: providerId } = data;
        acc[providerId] = data.workingHours?.map((workingHour) => ({
          ...workingHour,
          startDate: moment(`${workingHour.date} ${workingHour.start}`).toDate(),
          endDate: moment(`${workingHour.date} ${workingHour.end}`).toDate(),
        }));
        return acc;
      }, {}),
    [medspaAppointments]
  );

  const dummyData = useMemo(() => {
    const testData =
      selectedStatus === AppointmentStatusEnum.CANCELLED ? [...appointments] : [...appointments, ...blockOffs];

    return testData
      .map((dummyAppointment) => ({
        ...dummyAppointment,
        practitionerId: provider.id,
      }))
      .filter((appointment) => {
        if (appointment.bookingType !== BookingTypeEnum.APPOINTMENT) {
          return true;
        }

        // Status filtering
        if (selectedStatus === AppointmentStatusEnum.CANCELLED) {
          return appointment.status === AppointmentStatusEnum.CANCELLED;
        }
        if (selectedStatus === '') {
          if (appointment.status === AppointmentStatusEnum.CANCELLED) {
            return false;
          }
        } else if (appointment.status !== selectedStatus) {
          return false;
        }

        // Other filters remain the same
        return (
          (selectedGFEStatus === '' || appointment.gfeStatus === selectedGFEStatus) &&
          (selectedAppTypeId === '' || appointment.appointmentTypeId === selectedAppTypeId)
        );
      });
  }, [appointments, blockOffs, provider.id, selectedGFEStatus, selectedAppTypeId, selectedStatus]);

  const IconComponent = ({ gfeStatus }: { gfeStatus: MedspaCalendarGFEStatusEnum }) => {
    const Component = getGFEStatusIconComponent(gfeStatus);
    return <Component />;
  };

  const providersMap = useMemo(
    () => ({
      [practitionerId]: provider,
    }),
    [practitionerId, provider]
  );

  const onChangeCurrentViewName = useCallback((name: string) => {
    if (name === VIEW_NAME_WEEK) {
      setCurrentViewName(VIEW_NAME_WEEK_PROVIDER);
    } else {
      setCurrentViewName(name);
    }
  }, []);

  return (
    <Page title="Calendar">
      <Box display="flex" flexDirection="column" height="100%" gridGap="1rem">
        {availableMedspaLocations.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <EHRSelect
                label="Location"
                dataCy="select-location"
                onChange={(value: string | number) =>
                  setSelectedMedspaLocation(
                    medspaLocations.find((medspaLocation: IMedspaLocation) => medspaLocation.id === +value)
                  )
                }
                id="medspaLocation"
                value={selectedMedspaLocation?.id || null}
                fullWidth
              >
                {sortBy(medspaLocations, (medspaLocation: IMedspaLocation) => medspaLocation.name).map(
                  (medspaLocation: IMedspaLocation) => {
                    const { name, address, city, state, zipCode } = medspaLocation;
                    return (
                      <MenuItem key={medspaLocation.id} value={medspaLocation.id}>
                        <strong>{name}</strong>&nbsp;/ {address}, {city}, {state}, {zipCode}
                      </MenuItem>
                    );
                  }
                )}
              </EHRSelect>
            </Grid>
            <Grid item xs={2}>
              <EHRSelect
                label="Appointments"
                dataCy="select-app-type"
                onChange={(value: string | number) => setSelectedAppTypeId(value)}
                id="appointments"
                value={selectedAppTypeId || ''}
                fullWidth
                displayEmpty
              >
                <MenuItem value="">All Appointments</MenuItem>
                {enabledAppTypes.map((appType: IAppointmentType) => (
                  <MenuItem key={appType.id} value={appType.id}>
                    {appType.name}
                  </MenuItem>
                ))}
              </EHRSelect>
            </Grid>
            <Grid item xs={2}>
              <EHRSelect
                label="Status"
                dataCy="select-status"
                onChange={(value: string | number) => setSelectedStatus(value as string)}
                id="status"
                value={selectedStatus || ''}
                fullWidth
                displayEmpty
              >
                <MenuItem value="">Any Status</MenuItem>
                <MenuItem value={AppointmentStatusEnum.RESERVED}>
                  <Box display="flex" gridGap="0.25rem" alignItems="center">
                    In Reservation
                  </Box>
                </MenuItem>
                <MenuItem value={AppointmentStatusEnum.PENDING_CONFIRMATION}>
                  <Box display="flex" gridGap="0.25rem" alignItems="center">
                    Pending Confirmation
                  </Box>
                </MenuItem>
                <MenuItem value={AppointmentStatusEnum.CONFIRMED}>
                  <Box display="flex" gridGap="0.25rem" alignItems="center">
                    Confirmed
                  </Box>
                </MenuItem>
                <MenuItem value={AppointmentStatusEnum.CANCELLED}>
                  <Box display="flex" gridGap="0.25rem" alignItems="center">
                    Canceled
                  </Box>
                </MenuItem>
              </EHRSelect>
            </Grid>
            <Grid item xs={2}>
              <EHRSelect
                label="GFE Status"
                dataCy="select-gfe-status"
                onChange={(value: string | number) => setSelectedGFEStatus(value as string)}
                id="gfeStatus"
                value={selectedGFEStatus || ''}
                fullWidth
                displayEmpty
              >
                <MenuItem value="">Any Status</MenuItem>
                <MenuItem value={MedspaCalendarGFEStatusEnum.TO_CLEAR}>
                  <span style={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
                    <IconComponent gfeStatus={MedspaCalendarGFEStatusEnum.TO_CLEAR} />
                    To Clear
                  </span>
                </MenuItem>
                <MenuItem value={MedspaCalendarGFEStatusEnum.TO_RECLEAR}>
                  <span style={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
                    <IconComponent gfeStatus={MedspaCalendarGFEStatusEnum.TO_RECLEAR} />
                    To Re-Clear
                  </span>
                </MenuItem>
                <MenuItem value={MedspaCalendarGFEStatusEnum.INCOMPLETE}>
                  <span style={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
                    <IconComponent gfeStatus={MedspaCalendarGFEStatusEnum.INCOMPLETE} />
                    Incomplete
                  </span>
                </MenuItem>
                <MenuItem value={MedspaCalendarGFEStatusEnum.CLEARED}>
                  <span style={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
                    <IconComponent gfeStatus={MedspaCalendarGFEStatusEnum.CLEARED} />
                    Cleared
                  </span>
                </MenuItem>
              </EHRSelect>
            </Grid>
          </Grid>
        )}
        {!isLoading && availableMedspaLocations.length > 0 && enabledAppTypes.length > 0 && (
          <Box display="flex">
            <MedspaCalendar
              appointmentsData={dummyData}
              selectedPractitionerId={practitionerId}
              providerResources={providerResources}
              // @ts-ignore
              providersMap={providersMap}
              selectedMedspaLocation={selectedMedspaLocation}
              date={currentDate}
              onDateChange={(newDate) => setCurrentDate(newDate)}
              appointmentTypes={enabledAppTypes}
              workingHoursMap={workingHoursMap}
              currentViewName={currentViewName}
              isFetching={isFetchingMedspaAppointments}
              setCurrentViewName={onChangeCurrentViewName}
              onChangePractitionerSelected={() => {}}
            />
          </Box>
        )}
        {!isLoading && !availableMedspaLocations.length && (
          <Box display="flex">
            <Box className={classes.missingDataContainer}>
              <Box style={{ textAlign: 'center', width: '50%', maxWidth: '500px' }}>
                <h4>No active provider available for this location</h4>
                <p style={{ marginBottom: '1rem' }}>
                  It looks like you don&apos;t have any active locations for scheduling. Please contact your MedSpa
                  admin to configure your calendars.
                </p>
              </Box>
            </Box>
          </Box>
        )}
        {!isLoading && availableMedspaLocations.length > 0 && !enabledAppTypes.length && (
          <Box display="flex">
            <Box className={classes.missingDataContainer}>
              <Box style={{ textAlign: 'center', width: '50%', maxWidth: '500px' }}>
                <h4>No active appointment types for this location</h4>
                <p style={{ marginBottom: '1rem' }}>
                  It looks like you don&apos;t have any active appointment types enabled for this location. Please
                  contact your MedSpa admin to configure appointment types for this location.
                </p>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Page>
  );
};

export default MedspaPractitionerCalendar;
