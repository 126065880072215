import React from 'react';
import PrivateRoute from 'src/utils/PrivateRoute';
import { Switch } from 'react-router-dom';
import AccountSettings from 'src/pages/Onboarding/AccountSettings';
import ToDoOnboarding from 'src/pages/MedspaAdmin/Onboarding/ToDo';
import MerchantRefresh from 'src/pages/MedspaAdmin/Onboarding/MerchantRefresh';

export const ONBOARDING_BASE_PATH = `/medspa-admin/onboarding`;
export const ONBOARDING_ACCOUNT_SETTINGS = `${ONBOARDING_BASE_PATH}/account-settings`;
export const ONBOARDING_MERCHANT_ONBOARDING = `${ONBOARDING_BASE_PATH}/merchants/refresh`;

const MedspaAdminOnboardingRoutes: React.FC = () => (
  <Switch>
    <PrivateRoute exact path={ONBOARDING_ACCOUNT_SETTINGS} component={AccountSettings} />
    <PrivateRoute exact path={ONBOARDING_BASE_PATH} component={() => <ToDoOnboarding />} />
    <PrivateRoute exact path={ONBOARDING_MERCHANT_ONBOARDING} component={() => <MerchantRefresh />} />
  </Switch>
);

export default MedspaAdminOnboardingRoutes;
