import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';
import {
  Container,
  CircularProgress,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Box,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { InfoOutlined } from '@material-ui/icons';
import { STRIPE_REFUND_FEE_MESSAGE } from 'src/constants/stripe.constants';
import { SQUARE_STATUS } from '../../../constants/square.constants';
import { dispatch, RootState } from '../../../rematch';
import { CustomButton, RefundButton, secondaryButtonStyles, useStyles } from './footer.styles';
import { ReviewModal } from './ReviewModal/index';
import Analytics from '../../../utils/analytics';
import { userInteractionProps, USER_INTERACTION_EVENT } from '../../../constants/analytics.constants';

interface Props {
  transactionStatus: string;
  transactionInProgress: boolean;
  processingPaymentIntervalRef: React.MutableRefObject<number | undefined>;
  isValidationLoading: boolean;
  totalAmount: string;
  initiateTransaction: ({ processingPayment }: { processingPayment: boolean }) => void;
  isServiceVisitOpen: boolean;
  processedInSquare: boolean;
  isAccountChargeServiceVisit?: boolean;
  toPay: number;
}

export const Footer = (props: Props) => {
  const classes = useStyles();
  const practitioner = useSelector(({ auth }: RootState) => auth);
  const { serviceVisitId, patientId }: any = useParams();
  const {
    transactionStatus,
    transactionInProgress,
    processingPaymentIntervalRef,
    isValidationLoading,
    processedInSquare,
  } = props;
  const { initiateTransaction, totalAmount, isServiceVisitOpen, isAccountChargeServiceVisit, toPay } = props;
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDialogPositiveReviewOpen, setIsDialogPositiveReviewOpen] = useState<boolean>(false);
  const [refundInProgress, setRefundInProgress] = useState<boolean>(false);

  const getProcessButtonDisable = (): boolean =>
    transactionInProgress || transactionStatus === SQUARE_STATUS.OK ? true : false || isValidationLoading;

  const initiateRefundUndo = (): void => {
    setRefundInProgress(true);

    dispatch({
      type: 'newServiceVisit/refundUndo',
      payload: {
        finallyCallback: () => {
          setRefundInProgress(false);
        },
        checkout: { patientId, serviceVisitId },
        processedInSquare,
        isAccountChargeServiceVisit,
      },
    });
  };

  const onAskForReview = () => {
    setIsDialogPositiveReviewOpen(true);

    const segmentAnonymousId = localStorage.ajs_anonymous_id?.split('"').join('') || '';

    Analytics.identify(segmentAnonymousId, {
      type: 'provider',
      name: practitioner.name,
      email: practitioner.email,
      provider_id: practitioner.id,
    });

    Analytics.track(USER_INTERACTION_EVENT, {
      ...userInteractionProps,
      url: window.location.href,
      initiator: practitioner.id,
      event_details: { cta: true },
    });
  };

  const buttonLabel = (): string => {
    if (refundInProgress) {
      return 'IN PROGRESS';
    }
    if (processedInSquare) {
      return 'REFUND';
    }
    return 'UNDO';
  };

  const buttonTooltip = () =>
    practitioner.paymentProcessor !== STRIPE_PAYMENT_PROCESSOR ? null : (
      <Tooltip title={STRIPE_REFUND_FEE_MESSAGE} placement="right" arrow>
        <InfoOutlined className={classes.refundTooltip} />
      </Tooltip>
    );

  const navigateBack = () => {
    history.push(
      isAccountChargeServiceVisit ? `/patient/${patientId}` : `/patient/${patientId}/serviceVisit/${serviceVisitId}`
    );
  };

  return (
    <>
      <Divider />
      <Container className={classes.footer}>
        <div>
          {transactionStatus === SQUARE_STATUS.OK && (
            <CustomButton data-cy="back" onClick={navigateBack} style={secondaryButtonStyles}>
              Back to {isAccountChargeServiceVisit ? 'Profile' : 'Service'}
            </CustomButton>
          )}
          {transactionStatus === SQUARE_STATUS.OK && (
            <Box className={classes.refundButtonContainer}>
              <RefundButton
                data-cy="refundBtn"
                onClick={() => setIsDialogOpen(true)}
                disabled={refundInProgress || !isServiceVisitOpen}
              >
                {buttonLabel()}
              </RefundButton>
              {buttonTooltip()}
            </Box>
          )}
        </div>

        <div>
          {transactionStatus === SQUARE_STATUS.OK && !refundInProgress && (
            <CustomButton data-cy="reviewBtn" onClick={onAskForReview} style={secondaryButtonStyles}>
              Ask For Review
            </CustomButton>
          )}
          {Boolean(transactionStatus !== SQUARE_STATUS.OK && !isAccountChargeServiceVisit && toPay && toPay > 0) && (
            <CustomButton
              data-cy="chargeCardOnFile"
              disabled={Boolean(processingPaymentIntervalRef?.current)}
              onClick={() => initiateTransaction({ processingPayment: false })}
              style={secondaryButtonStyles}
            >
              Charge Card on File
            </CustomButton>
          )}
          {/* eslint-disable no-nested-ternary */}
          {!processingPaymentIntervalRef.current && transactionStatus !== SQUARE_STATUS.OK ? (
            <CustomButton
              data-cy="processBtn"
              onClick={() => initiateTransaction({ processingPayment: true })}
              disabled={getProcessButtonDisable()}
            >
              PROCESS: ${totalAmount}
            </CustomButton>
          ) : transactionStatus !== SQUARE_STATUS.OK ? (
            <CustomButton>
              <>
                PROCESSING <CircularProgress size={20} className={`${classes.colorWhite} ${classes.ml2}`} />
              </>
            </CustomButton>
          ) : null}
        </div>
      </Container>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <div className={classes.containerDialog}>
          <DialogTitle className={classes.title}>{processedInSquare ? 'Refund' : 'Undo'}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.content}>
              {processedInSquare ? 'Are you sure you want to make a refund?' : 'Are you sure you want to undo?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button className={`${classes.buttonLeft}`} onClick={() => setIsDialogOpen(false)}>
              No
            </Button>
            <Button className={`${classes.buttonRight}`} onClick={initiateRefundUndo}>
              Yes, {processedInSquare ? 'Refund' : 'Undo'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <ReviewModal
        isDialogPositiveReviewOpen={isDialogPositiveReviewOpen}
        setIsDialogPositiveReviewOpen={setIsDialogPositiveReviewOpen}
      />
    </>
  );
};
