import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useMerchantOnboardingRefreshUrl } from 'src/hooks/queries/medspaAdmins/useMerchantOnboardingRefreshUrl';
import { useStyles } from './index.styles';

const MerchantRefresh = () => {
  const classes = useStyles();

  useMerchantOnboardingRefreshUrl();

  return (
    <div className={classes.loaderContainer}>
      <CircularProgress />
    </div>
  );
};

export default MerchantRefresh;
