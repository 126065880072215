import { useMutation, useQuery, useQueryClient } from 'react-query';
import Payments, { PaymentUserOptions, StripePaymentSetupOptions } from 'src/services/Payments';
import { handleQueryError } from 'src/utils/inventoryOrdering';
import { PAYMENT_METHODS } from 'src/constants/reactQuery.keys';

export type PaymentOptions = PaymentUserOptions & { disabled?: boolean };

export const useSetupIntent = ({ customerId, userGroupId, ...params }: PaymentOptions) =>
  useQuery({
    queryKey: [customerId ? 'customerPaymentIntent' : 'medspaPaymentIntent', customerId || userGroupId, params],
    queryFn: async () =>
      Payments.createPaymentIntent({
        ...params,
        ...(customerId ? { customerId } : { userGroupId: userGroupId ?? 0 }),
      }),
    onError: handleQueryError('creating payment intent'),
    refetchOnWindowFocus: false,
    enabled: !!(customerId || userGroupId) && !params.disabled,
  });

export const useSavePaymentMethod = ({ customerId, userGroupId }: PaymentUserOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: StripePaymentSetupOptions) =>
      Payments.savePaymentMethod({
        ...params,
        ...(customerId ? { customerId } : { userGroupId: userGroupId ?? 0 }),
      }),
    onError: handleQueryError('saving payment method'),
    onSuccess: () => {
      queryClient.invalidateQueries(PAYMENT_METHODS);
    },
  });
};
