import React from 'react';
import { uniq, sumBy, round } from 'lodash';
import moment from 'moment';
import {
  IAsset,
  IDashboardItem,
  IDashboardProduct,
  IReconciliationReport,
  IReconciliationReportItem,
} from '../interfaces/reconciliation.interfaces';
import { IN_STOCK_STATUS, INVENTORY_COLORS } from '../constants/inventory.constants';

export const getHumanReadableInternalSerialNumber = (serialNumber: string): string =>
  `${serialNumber.slice(0, 3)} *${serialNumber.slice(serialNumber.length - 6)}`;

export const getLastInternalSerialNumberId = (serialNumber: string): string => {
  const sections = serialNumber.split('_');
  return sections[sections.length - 1];
};

export const getDetachedSerialId = (serialNumber: string): string[] => {
  const serial = getLastInternalSerialNumberId(serialNumber);
  const serialFirst = serial.slice(0, serial.length - 2) || '';
  const serialLast = serial.slice(serial.length - 2) || '';
  return [serialFirst, serialLast];
};

export const getTotal = (arr: any[] = [], key: string): number => {
  let total = 0;

  arr.forEach((item) => {
    total += item[key];
  });

  return total;
};

export const itemCost = (item: IAsset, unitCount: number): number =>
  round((item?.reimbursementUnitCost || 0) * (item.allowPartialSale ? unitCount : 1), 2);

export const getReimbursementTotal = (items: IAsset[]): number => {
  let total = 0;

  items.forEach((item: IAsset): void => {
    if (item.status === IN_STOCK_STATUS) {
      total += itemCost(item, item.currentNumberOfUnits);
    } else {
      const adjustedUnits = item.expiredUnits || item.markedAsEmptyUnits || 0;

      if (adjustedUnits > 0) {
        total += itemCost(item, adjustedUnits);
      }
    }
  });

  return total;
};

export const getCostTotal = (items: IReconciliationReportItem[]): number =>
  sumBy(items, (item: IReconciliationReportItem): number => item?.cost || 0) || 0;

export const getProductIdentifiersData = (assets: any[], scanned: boolean = false, unitLabel: string = ''): any => {
  const units = getTotal(assets, 'currentNumberOfUnits');
  return {
    productName: assets[0].name || assets[0].productName || assets[0].product,
    hasScannedProducts: scanned,
    units: assets[0].allowPartialSale ? units : 0,
    vials: assets.length,
    unitLabel,
    identifiers: assets,
  };
};

export const addProductListToReconciliationReport = (reports: IReconciliationReport[]) => {
  reports.forEach((report) => {
    const { reconciliationReportItems } = report;
    report.productsList = getProductList(reconciliationReportItems); // eslint-disable-line no-param-reassign
  });

  return reports;
};

export const getProductList = (reconciliationReportItems: IReconciliationReportItem[]) => {
  const serviceIds: number[] = uniq(reconciliationReportItems?.map(({ serviceId }) => serviceId));

  return serviceIds.map((id) => {
    const filteredItems = reconciliationReportItems.filter(({ serviceId }) => serviceId === id);
    const markedAsEmptyList = filteredItems.filter(({ markedAsEmpty }: IReconciliationReportItem) => markedAsEmpty);
    const expiredList = filteredItems.filter(({ expired }) => expired > 0);
    const missingList = filteredItems.filter(
      ({ missing, markedAsEmpty, expired, disposed }: any) => missing && !markedAsEmpty && !expired && !disposed
    );

    return {
      serviceId: id,
      product: filteredItems[0]?.product,
      expected: filteredItems.filter(({ expected }: any) => expected).length,
      expectedUnits: getTotal(filteredItems, 'expected'),
      counted: filteredItems.filter(({ counted }: any) => counted).length,
      countedUnits: getTotal(filteredItems, 'counted'),
      missing: missingList.length,
      missingUnits: getTotal(missingList, 'missing'),
      practitionerNote: filteredItems[0]?.practitionerNote,
      waived: getTotal(filteredItems, 'waived'),
      allowPartialSale: filteredItems[0]?.allowPartialSale,
      markedAsEmpty: markedAsEmptyList.length,
      markedAsEmptyUnits: getTotal(markedAsEmptyList, 'disposed'),
      expired: expiredList.length,
      expiredUnits: getTotal(expiredList, 'expired'),
      open: false,
      cost: getCostTotal([...markedAsEmptyList, ...expiredList, ...missingList]),
    };
  });
};

export const twoDecimalPlacesIfCents = (amount: number | undefined) => {
  if (amount === undefined) {
    return 0;
  }
  return amount % 1 !== 0 ? amount.toFixed(2) : amount;
};

export const showQuantityUnits = ({ allowPartialSale, quantity, units, showRedText }: any) => (
  <>
    <div style={showRedText && { color: !quantity ? '#393B3F' : '#FF5252' }}>
      {quantity} {allowPartialSale && getPluralWord('Vial', quantity === 0 || quantity > 1)}
    </div>
    {allowPartialSale && (
      <div style={showRedText && { color: !units ? '#393B3F' : '#FF5252' }}>
        {twoDecimalPlacesIfCents(units)} {getPluralWord('Unit', units === 0 || units > 1)}
      </div>
    )}
  </>
);

export const isValidSerialNumber = (text: string) => {
  // format: BTX_123ABC_20201212_WXYZ
  const regexQR = /\w+_[\w\s]+_\d{8}_\w+/g;
  return regexQR.test(text);
};

export const getPluralWord = (word: string, isPlural: boolean): string => (isPlural ? `${word}s` : word);

export const getPrefixByDigits = (serialNumber: string, remainingAssets: IAsset[]) => {
  let prefix = null;

  remainingAssets.forEach(({ internalSerialNumber }: any) => {
    if (internalSerialNumber.split('_').pop() === serialNumber) {
      // eslint-disable-next-line prefer-destructuring
      prefix = internalSerialNumber.split('_')[0];
    }
  });

  return prefix;
};

export const showAssetScannedStatusFormat = (asset: IAsset, showUnitDetails: boolean) => {
  const { currentNumberOfUnits, initialNumberOfUnits, counted } = asset;

  if (showUnitDetails) {
    return (
      <span>
        {counted === undefined ? (
          <span>
            {twoDecimalPlacesIfCents(currentNumberOfUnits || 0)}/{twoDecimalPlacesIfCents(initialNumberOfUnits)}
          </span>
        ) : (
          <span>
            {twoDecimalPlacesIfCents(currentNumberOfUnits || 0)}/
            <span style={{ color: '#12574D', fontWeight: 'bold' }}>{twoDecimalPlacesIfCents(counted || 0)}</span>/
            {twoDecimalPlacesIfCents(initialNumberOfUnits)}
          </span>
        )}
      </span>
    );
  }

  return (
    <span>
      {counted === undefined ? (
        currentNumberOfUnits
      ) : (
        <span>
          {currentNumberOfUnits}/<span style={{ color: '#12574D', fontWeight: 'bold' }}>{counted || 0}</span>
          {asset.assetLabel && ` ${asset.assetLabel}`}
        </span>
      )}
    </span>
  );
};

/* Product at risk:
 * Is at risk 2 weeks before expire.
 * Expire with expire_at unless asset has reconstitution date.
 * reconstitution date expire with a parameterizable time or 30 days after reconstitution date
 * (at risk 2 week after reconstitution date) */
export const getExpirationDate = (asset: IAsset) => {
  if (asset.allowPartialSale && asset.reconstitutedAt) {
    return moment(asset.reconstitutedAt).add(asset.daysToExpireAfterOpened || 30, 'days');
  }
  return moment(asset.expireAt);
};

export const isAssetAboutToExpire = (asset: IAsset) => {
  if (asset.allowPartialSale && asset.reconstitutedAt) {
    // 2 weeks after reconstitution date is about to expire
    return moment().isSameOrAfter(moment(asset.reconstitutedAt).add(2, 'weeks'));
  }
  return asset.expireAt !== undefined ? moment().isSameOrAfter(moment(asset.expireAt).subtract(2, 'months')) : false;
};

export const isAssetExpired = (asset: IAsset) => {
  if (asset.allowPartialSale && asset.reconstitutedAt) {
    // parameterizable time or 30 days after reconstitution date is expired
    return moment().isSameOrAfter(moment(asset.reconstitutedAt).add(asset.daysToExpireAfterOpened || 30, 'days'));
  }
  return asset.expireAt !== undefined ? moment().isSameOrAfter(moment(asset.expireAt)) : false;
};

export const getBackgroundAssetColor = (asset: IAsset, hideDetailColors?: boolean) => {
  if (hideDetailColors) {
    return INVENTORY_COLORS.BOX_DEFAULT_BG;
  }
  let bgAsset = INVENTORY_COLORS.BOX_DEFAULT_BG;

  // Note: preserve priorities of conditionals colors
  if (asset.currentNumberOfUnits === 0) {
    bgAsset = INVENTORY_COLORS.BOX_ZERO_STOCK_BG; // gray
  } else if (isAssetExpired(asset)) {
    bgAsset = INVENTORY_COLORS.BOX_EXPIRED_BG; // red
  } else if (isAssetAboutToExpire(asset)) {
    bgAsset = INVENTORY_COLORS.BOX_ABOUT_TO_EXPIRE_BG; // peach
  } else if (asset.markedAsEmpty) {
    // low priority
    bgAsset = INVENTORY_COLORS.BOX_ZERO_STOCK_BG;
  }

  return bgAsset;
};

export const formatNumber = (value: number | string): string => {
  if (typeof value === 'string') {
    return value;
  }
  if (!value) {
    return (0.0).toFixed(2);
  }
  return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const getBackgroundAssetActionColor = (asset: IAsset, hideDetailColors?: boolean) => {
  if (hideDetailColors) {
    return INVENTORY_COLORS.ACTION_DEFAULT_BG;
  }

  let bgAction = INVENTORY_COLORS.ACTION_DEFAULT_BG;

  // Note: preserve priorities of conditionals colors
  if (asset.currentNumberOfUnits === 0) {
    bgAction = INVENTORY_COLORS.ACTION_ZERO_STOCK_BG; // 0 units - gray
  } else if (asset.counted !== undefined && asset.currentNumberOfUnits > asset.counted) {
    bgAction = INVENTORY_COLORS.ACTION_MISSING_BG; // has missing - peach
  }

  return bgAction;
};

export const assetsByPurchaseOrderItem = (dashboardList: IDashboardItem[], purchaseOrderItemId: number): IAsset[] =>
  dashboardList
    .flatMap(
      (item: IDashboardItem): IAsset[] => item.products?.flatMap((product: IDashboardProduct) => product.assets) || []
    )
    .filter((item: IAsset): boolean => item.purchaseOrderItemId === purchaseOrderItemId);

export const dashboardListValue = (dashboardList: IDashboardItem[]): number => {
  const inventoryItems = dashboardList.flatMap(
    (item: IDashboardItem): IAsset[] => item.products?.flatMap((product: IDashboardProduct) => product.assets) || []
  );

  return sumBy(inventoryItems, 'reimbursementCost');
};
