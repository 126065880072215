// import { useServiceRequestApprovalDraft } from '../hooks/queries/useServiceRequestApprovalDraft';
import * as Sentry from '@sentry/react';
import { CustomerParams } from '../interfaces/CustomerParams';
import { IPhoto } from '../interfaces/global.interfaces';
import IDefaultDiagnosis from '../interfaces/IDefaultDiagnosis';
import CustomersResponseType from '../types/CustomersResponseType';
import Id from '../types/Id';
import Api from './Api';
import API_ROUTES from '../constants/apiRoutes.constants';
import { ICreateCustomerPhotoConsent, ICustomerPhotoConsent, IUpdateCustomerPhotoConsent } from '../types/Patient';

const Customers = {
  async uploadPhoto(patientId: string, formData: FormData): Promise<{ photos: IPhoto[]; error: Error }> {
    const { photos, error } = await Api.post(`/customers/${patientId}/photos/create`, formData);
    return { photos, error };
  },

  async deletePhoto(patientId: string, photoId: string, deletePhotoReason: string): Promise<string | undefined> {
    const { data, error } = await Api.post(`/customers/${patientId}/photos/${photoId}/delete`, { deletePhotoReason });
    if (error) {
      throw error;
    }
    return data;
  },

  async getPhotos(patientId: Id) {
    const { customer, error } = await Api.get(`/customers/${patientId}`);

    if (error) {
      throw error;
    }
    return customer.photos || [];
  },

  async markPhotoAsSeen(photoId: Id) {
    const { photo, success, message } = await Api.put(`v3/photos/${photoId}/mark_as_seen`, {});

    if (!success) {
      throw message;
    }
    return photo;
  },

  async getCustomers({
    dashboard,
    group,
    view,
    filter,
    page,
    search = '',
    sortBy = 'created_at DESC',
    exclude,
    version,
  }: CustomerParams): Promise<CustomersResponseType> {
    const basePath = `/customers/for-${dashboard}`;

    const paramsList = [`search=${encodeURIComponent(search)}`];
    if (view) {
      paramsList.push(`list=${view}`);
    }
    if (page) {
      paramsList.push(`page=${page}`);
    }
    if (group) {
      paramsList.push(`group=${group}`);
    }
    if (filter) {
      paramsList.push(`filter=${filter}`);
    }
    if (sortBy) {
      paramsList.push(`sort=${sortBy}`);
    }
    if (version) {
      paramsList.push(`version=${version}`);
    }
    if (exclude) {
      paramsList.push(`exclude=${exclude}`);
    }
    if (dashboard === 'physician') {
      paramsList.push('from_new_ehr=true');
    }

    return Api.get(`${basePath}?${paramsList.join('&')}`);
  },

  async getDefaultDiagnoses(): Promise<IDefaultDiagnosis[]> {
    const { defaultDiagnoses, error } = await Api.get(`/customers/diagnoses/default-diagnoses`);
    if (error) {
      throw error;
    }

    return defaultDiagnoses;
  },

  async markImportantPatient({ patientId, star }: { patientId: string; star: boolean }): Promise<any> {
    if (!patientId) {
      throw new Error('Something went wrong');
    }

    const method = star ? 'post' : 'delete';
    const { data, error } = await Api[method](`/customers/${patientId}/star`, {});

    if (error) {
      throw error;
    }
    return data;
  },
  async markImportantPatientV3({ patientId, marked }: { patientId: string | number; marked: boolean }): Promise<any> {
    if (!patientId) {
      throw new Error('Something went wrong');
    }

    const method = marked ? 'post' : 'delete';
    const { data, error } = await Api[method](`/v3/customers/${patientId}/mark_important`, {});

    if (error) {
      throw error;
    }
    return data;
  },

  async getPhysicianApprovalDraft(patientId: number): Promise<any> {
    if (!patientId) {
      throw new Error('Something went wrong');
    }
    const { success, serviceRequestApprovalDraft } = await Api.get(
      `/v3/physician_approval_draft?customer_id=${patientId}`
    );

    if (!success) {
      return {};
    }
    return serviceRequestApprovalDraft.payload;
  },

  async updateServiceRequestApprovalDraft({ patientId, payload }: any): Promise<any> {
    const { results, error } = await Api.post(`/v3/physician_approval_draft`, {
      customerId: patientId,
      payload,
    });
    if (error) {
      throw error;
    }
    return results;
  },
  async updateIgnoredPatient(patientId: string) {
    const { data, error } = await Api.post(`/customers/${patientId}/toggle_ignore`, {});
    if (error) {
      throw error;
    }
    return data;
  },
  async moveToReclear(patientId: string): Promise<{ success: boolean }> {
    try {
      const { success } = await Api.post(`/customers/${patientId}/move_to_reclear`, {});
      if (!success) {
        const error = new Error('Something went wrong');
        Sentry.captureMessage(error.message, 'debug' as Sentry.Severity);
        throw error;
      }

      return success;
    } catch (error) {
      let messageError: string;
      if (error instanceof Error) {
        messageError = error.message;
      } else {
        messageError = (error as string).toString();
      }
      Sentry.captureMessage(messageError, 'debug' as Sentry.Severity);
      throw error;
    }
  },
  async createCustomerPhotoConsent({
    patientId,
    serviceVisitId,
    signatureImage,
    patientName,
  }: ICreateCustomerPhotoConsent): Promise<ICustomerPhotoConsent> {
    const { customerPhotoConsent, error } = await Api.post(API_ROUTES.CREATE_CUSTOMER_PHOTO_CONSENT(patientId), {
      signatureImage,
      patientName,
      serviceVisitId,
    });
    if (error) {
      throw error;
    }
    return customerPhotoConsent;
  },
  async createCustomerPhotoConsentV2({
    patientId,
    serviceVisitId,
    signatureImage,
    patientName,
    generalPhotoConsent,
    deidentifiedConsent,
  }: ICreateCustomerPhotoConsent): Promise<ICustomerPhotoConsent> {
    const { customerPhotoConsent, error } = await Api.post(API_ROUTES.CREATE_CUSTOMER_PHOTO_CONSENT_V2(+patientId), {
      signatureImage,
      patientName,
      serviceVisitId,
      generalPhotoConsent,
      deidentifiedConsent,
    });
    if (error) {
      throw error;
    }
    return customerPhotoConsent;
  },
  async updateCustomerPhotoConsent({
    id,
    patientId,
    generalPhotoConsent,
    deidentifiedConsent,
  }: IUpdateCustomerPhotoConsent): Promise<ICustomerPhotoConsent> {
    const { customerPhotoConsent, error } = await Api.put(API_ROUTES.UPDATE_CUSTOMER_PHOTO_CONSENT_V2(patientId, id), {
      generalPhotoConsent,
      deidentifiedConsent,
    });
    if (error) {
      throw error;
    }
    return customerPhotoConsent;
  },
  async getCustomerPhotoConsent({
    patientId,
    serviceVisitId,
  }: {
    patientId: number;
    serviceVisitId: number;
  }): Promise<ICustomerPhotoConsent> {
    const { customerPhotoConsent, error } = await Api.get(
      `${API_ROUTES.GET_CUSTOMER_PHOTO_CONSENT(patientId)}?service_visit_id=${serviceVisitId}`
    );
    if (error) {
      throw error;
    }
    return customerPhotoConsent;
  },
  async createCustomerBeautiBankSubscription({
    patientId,
    squareCardId,
  }: {
    patientId: string;
    squareCardId: string | undefined;
  }) {
    const { success, message } = await Api.post(API_ROUTES.CREATE_CUSTOMER_BEAUTI_BANK_SUBSCRIPTION(patientId), {
      card_id: squareCardId,
    });

    if (!success) {
      throw message;
    }
    return success;
  },
  async updateCustomerBeautiBankSubscription({
    patientId,
    autoRenew,
    squareCardId,
    acknowledgedLastSquareError,
  }: {
    patientId: string;
    autoRenew?: boolean;
    squareCardId?: string;
    acknowledgedLastSquareError?: boolean;
  }) {
    const { success, message } = await Api.post(API_ROUTES.UPDATE_CUSTOMER_BEAUTI_BANK_SUBSCRIPTION(patientId), {
      autoRenew,
      squareCardId,
      acknowledgedLastSquareError,
    });

    if (!success) {
      throw message;
    }
    return success;
  },
  async getPractitionerAssigned(customerId: number): Promise<any> {
    const { practitionersAssigned } = await Api.get(API_ROUTES.CUSTOMER_PRACTITIONERS_ASSIGNED, { customerId });
    return practitionersAssigned;
  },
  async updatePractitionerAssigned(data: any): Promise<any> {
    return Api.put(API_ROUTES.CUSTOMER_PRACTITIONERS_ASSIGNED, data);
  },
  async getCustomerForSelector(params: {
    search?: string;
    practitionerId?: number | string;
  }): Promise<{ id: number; email: string; fullName: string }[]> {
    const { customers } = await Api.get(API_ROUTES.CUSTOMER_FOR_SELECTOR, params);
    return customers;
  },
  async noShow(customerId: number) {
    const { success, customer } = await Api.post(API_ROUTES.CUSTOMER_NO_SHOW(customerId), {});
    if (!success) {
      throw new Error('Something went wrong to mark customer as no show');
    }
    return customer;
  },
  async removePaymentMethod(customerId: number, creditCardId: string) {
    const { success, customer } = await Api.delete(
      `${API_ROUTES.CUSTOMER_REMOVE_PAYMENT_METHOD(customerId)}?external_payment_id=${creditCardId}`
    );
    if (!success) {
      throw new Error('Something went wrong removing credit card');
    }
    return customer;
  },
};

export default Customers;
