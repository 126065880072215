import React from 'react';
import { Modal } from '@material-ui/core';
import { Button } from '../../../components/common/Button';
import { useStyles } from './CreditCardModal.styles';
import { dispatch } from '../../../rematch';
import { useRemovePaymentMethod } from '../../../hooks/payments/useRemovePaymentMethod';

export const DeleteCreditCardModal = ({
  open,
  handleCloseCallback,
  creditCardId,
  patientId,
  onCardRemoved,
  paymentProcessor,
}: {
  open: boolean;
  handleCloseCallback: () => void;
  creditCardId: string;
  patientId: number;
  onCardRemoved: () => void;
  paymentProcessor?: string;
}) => {
  const classes = useStyles();
  const { mutateAsync: removeCard } = useRemovePaymentMethod(onCardRemoved);
  const handleDeleteCard = async () => {
    if (paymentProcessor) {
      await removeCard({ patientId, creditCardId });
    } else {
      dispatch({
        type: 'patient/deleteCustomerCreditCard',
        payload: { patientId, creditCardId, successCallback: onCardRemoved },
      });
    }
    handleCloseCallback();
  };

  return (
    <Modal className={classes.modal} open={open} onClose={handleCloseCallback}>
      <div className={classes.modalContent}>
        <div className={classes.modalTitle}>Are you sure you want to delete this card?</div>
        <div>
          <Button className={classes.deleteButton} title="Confirm" onClick={handleDeleteCard} />
          <Button className={classes.cancelButton} onClick={handleCloseCallback} title="Cancel" />
        </div>
      </div>
    </Modal>
  );
};
