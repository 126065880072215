/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { logSentryMessageInfo } from 'src/services/Sentry';
import { CheckoutHeader } from '../Checkout/CheckoutHeader';
import {
  CARE_CREDIT_TENDER,
  LIMIT_FOR_DISCOUNT,
  CHECKOUT_ACCOUNT_CREDIT_ERROR,
  FEE_RELATED_CHARGE_TYPES,
  ALLE_WITH_CHERRY_TENDER,
  PATIENTFI_TENDER,
} from '../../constants/checkout.constants';
import { SQUARE_STATUS } from '../../constants/square.constants';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { useStyles } from './ServiceVisitCheckoutContainer.styles';
import { ACCOUNT_CHARGE_VISIT_TYPE, IN_PERSON_VISIT_TYPE } from '../../services/ServiceVisit';
import { useServiceVisit } from '../../hooks/serviceVisit/useServiceVisit';
import {
  usePayServiceVisitWithCardMutation,
  usePayZeroBalanceMutation,
  usePayStagingMutation,
  useServiceVisitCheckout,
  useUpdateServiceVisitCheckout,
  usePayServiceVisitWithStripeTerminalMutation,
  useConfirmPaymentWithStripeTerminalMutation,
} from '../../hooks/serviceVisit/useCheckout';
import { MultipleSkeleton } from '../../components/common/LoadingSkeleton';
import { useSelectedServices } from '../../hooks/serviceVisit/useLineItems';
import LineItemTable from '../../components/ServiceVisitCheckout/LineItemTable';
import { useServiceGroups, useServices } from '../../hooks/queries/services/useServices';
import IServiceGroup from '../../interfaces/IServiceGroup';
import IServices from '../../interfaces/IServices';
import { formatNumber, getDataParameter } from '../../utils/checkout.utils';
import { DiscountsSubTotal } from '../Checkout/DiscountsSubTotal/DiscountsSubTotal';
import { AddNote } from '../Checkout/AddNote/AddNote';
import { RemainingToPay } from './RemainingToPay';
import { AccountCredit } from '../Checkout/OtherTenders/AccountCredit';
import { useCustomPatientProfile } from '../../hooks/queries/useCustomPatientProfile';
import useDiscounts, { CheckoutLineItemDiscount } from '../../hooks/serviceVisit/useDiscounts';
import { IDiscount } from '../../interfaces/checkout.interfaces';
import OtherTenders from '../../components/ServiceVisitCheckout/OtherTenders/OtherTenders';
import useTenders, { addCheckoutTender } from '../../hooks/serviceVisit/useTenders';
import FutureCredits from '../../components/ServiceVisitCheckout/FutureCredits';
import useServiceVisitCredits, { useAppliedServiceVisitCredits } from '../../hooks/serviceVisit/useServiceVisitCredits';
import { ILineItem } from '../../interfaces/IServiceVisits';
import { ITender } from '../../interfaces/ITransaction.interfaces';
import { dispatch, RootState } from '../../rematch';
import compile from '../../utils/toastMessagesCompiler';
import useReferrals, { useFetchAppliedReferralCredits } from '../../hooks/serviceVisit/useReferrals';
import ReferralsSelector from '../../components/ServiceVisitCheckout/ReferralsSelector';
import ConfirmModal from '../../components/common/ConfirmModal';
import { Footer } from './Footer';
// eslint-disable-next-line max-len
import ProccessingPaymentInSquareModal from '../Checkout/ProccessingPaymentInSquareModal/ProccessingPaymentInSquareModal';
import { AccountCreditErrorModal } from '../Checkout/AccountCreditErrorModal';
import {
  AMOUNT_FOR_REFERRER,
  PRACTITIONER_ROLE,
  STRIPE_PAYMENT_PROCESSOR,
  TOTAL_AMOUNT_REDEEMABLE,
  USER_TYPES,
} from '../../constants/general.constants';
import { IAppliedServiceCredit, IServiceCredit } from '../../types/ServiceCredits';
import { hasAccessTo } from '../../utils/auth.utils';
import { READ_REFERRAL, READ_SERVICE_VISIT_DISCOUNT } from '../../constants/actions.constants';

const IS_PROD = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION';

const ServiceVisitCheckoutContainer = ({
  match: {
    params: { patientId, serviceVisitId },
  },
}: any) => {
  const styles = useStyles();
  const history = useHistory();
  const { data: serviceVisit, isLoading: isLoadingServiceVisit } = useServiceVisit(+serviceVisitId);
  const permissions = useSelector(({ auth }: RootState) => auth.permissions);
  const providerEmail = useSelector(({ auth }: RootState) => auth.email);

  const {
    data: checkout,
    isLoading: isLoadingCheckout,
    isFetching: isFetchingCheckout,
    refetch: refetchCheckout,
  } = useServiceVisitCheckout(+serviceVisitId);
  const { data: patientServices, isLoading: isLoadingServices } = useServices(+patientId, +serviceVisitId, true);
  const { data: serviceGroups, isLoading: isLoadingServiceGroups } = useServiceGroups(+patientId, +serviceVisitId);
  const { data: patient, isLoading: isLoadingPatient } = useCustomPatientProfile(patientId);

  const fetchDiscounts = permissions.length > 0 && hasAccessTo(READ_SERVICE_VISIT_DISCOUNT, permissions);
  const {
    data: discountsData,
    isLoading: isLoadingDiscounts,
    refetch: refetchDiscounts,
  } = useDiscounts(+serviceVisitId, fetchDiscounts);
  const loading =
    isLoadingCheckout ||
    isLoadingServiceVisit ||
    isLoadingServices ||
    isLoadingServiceGroups ||
    isLoadingPatient ||
    isLoadingDiscounts;

  const payStagingMutation = usePayStagingMutation(+serviceVisitId);
  const payZeroBalanceMutation = usePayZeroBalanceMutation(+serviceVisitId);
  const payWithCardOrCashMutation = usePayServiceVisitWithCardMutation(+serviceVisitId);
  const payWithStripeTerminalMutation = usePayServiceVisitWithStripeTerminalMutation(+serviceVisitId);
  const confirmPaymentWithStripeTerminalMutation = useConfirmPaymentWithStripeTerminalMutation(+serviceVisitId);
  const { mutateAsync: updateCheckout } = useUpdateServiceVisitCheckout(+serviceVisitId);
  const { mutateAsync: applyTender } = addCheckoutTender(+serviceVisitId);
  const { data: lineItems, refetch: refetchLineItems } = useSelectedServices(+serviceVisitId);

  const [accountCreditErrorModalOpen, setAccountCreditErrorModalOpen] = useState<boolean>(false);
  const [accountCredit, setAccountCredit] = useState<number | string>(checkout?.accountCreditUsed || '');
  const [note, setNote] = useState<string | undefined>(checkout?.note);
  const {
    data: checkoutTenders,
    isLoading: isLoadingCheckoutTenders,
    refetch: refetchCheckoutTenders,
  } = useTenders(+serviceVisitId);

  const { userType } = useSelector(({ auth }: any) => auth);
  const enabledEditUnitPrice = userType === USER_TYPES.PRACTITIONER;
  // Low priority requests
  const {
    data: serviceCredits,
    isLoading: isLoadingServiceCredits,
    refetch: refetchServiceCredits,
  } = useServiceVisitCredits(+serviceVisitId, !loading);
  const {
    data: referralCredits,
    isLoading: isLoadingReferralCredits,
    refetch: refetchReferrals,
  } = useReferrals(+patientId, !loading && permissions?.length > 0 && hasAccessTo(READ_REFERRAL, permissions));
  const {
    data: appliedReferralCredits,
    isLoading: isLoadingAppliedReferralCredits,
    isFetching: isFetchingAppliedReferred,
    refetch: refetchAppliedReferralCredits,
  } = useFetchAppliedReferralCredits(
    +serviceVisitId,
    !loading && permissions?.length > 0 && hasAccessTo(READ_REFERRAL, permissions)
  );

  const {
    data: appliedServiceCredits,
    isLoading: isLoadingAppliedServiceCredits,
    refetch: refetchAppliedServiceCredits,
  } = useAppliedServiceVisitCredits(+serviceVisitId, !loading);
  const [proccessingPaymentInSquare, setProccessingPaymentInSquare] = useState<boolean>(false);
  const [pollInterval, setPollInterval] = useState<number>();
  const [refundInProgress, setRefundInProgress] = useState<boolean>(false);
  const [remainingToPay, setRemainingToPay] = useState<number>(checkout?.toPay || 0);
  const [otherTenderTotal, setOtherTenderTotal] = useState<number>(checkout?.otherTendersTotal || 0);
  const [resetTenders, setResetTenders] = useState<boolean>(false);
  const [resetCredits, setResetCredits] = useState<boolean>(false);

  const isLoadingOtherTenders =
    isLoadingCheckoutTenders ||
    isLoadingServiceCredits ||
    isLoadingAppliedServiceCredits ||
    isLoadingReferralCredits ||
    isLoadingAppliedReferralCredits;

  /* eslint-disable no-nested-ternary */
  const referralValue = useMemo(
    () =>
      appliedReferralCredits?.length
        ? patient?.isAdvocate && patient?.hasReferred
          ? (appliedReferralCredits.length / 5) * TOTAL_AMOUNT_REDEEMABLE
          : appliedReferralCredits.length * AMOUNT_FOR_REFERRER
        : 0,
    [appliedReferralCredits]
  );

  const servicesIdMap: Record<number, IServices> = useMemo(
    () => patientServices.reduce((obj, service) => Object.assign(obj, { [service.id]: service }), {}),
    [patientServices]
  );

  const serviceToOverriddenPriceMap: Record<number, number> = useMemo(
    () =>
      lineItems?.reduce((obj, lineItem) => {
        if (
          lineItem.unitPriceOverride &&
          servicesIdMap[lineItem.serviceId] &&
          servicesIdMap[lineItem.serviceId].price !== lineItem.unitPriceOverride
        ) {
          return Object.assign(obj, { [lineItem.serviceId]: lineItem.unitPriceOverride });
        }
        return obj;
      }, {}),
    [lineItems, servicesIdMap]
  );

  const serviceCreditIdToServiceIdMap = useMemo(
    () =>
      serviceCredits?.reduce(
        (obj: Record<number, number>, serviceCredit: IServiceCredit) =>
          Object.assign(obj, { [serviceCredit.id]: serviceCredit.serviceId }),
        {}
      ),
    [serviceCredits]
  );

  const futureUseCreditsApplied = useMemo(() => {
    if (
      appliedServiceCredits &&
      Object.keys(servicesIdMap || {}).length &&
      Object.keys(serviceCreditIdToServiceIdMap || {}).length
    ) {
      return appliedServiceCredits.reduce((total: number, appliedServiceCredit: IAppliedServiceCredit) => {
        const serviceId = serviceCreditIdToServiceIdMap[appliedServiceCredit.serviceCreditId];
        if (serviceId) {
          const service = servicesIdMap[serviceId];
          if (service) {
            const serviceTotal = +service.price * appliedServiceCredit.value;
            return serviceTotal + total;
          }
        }
        return total;
      }, 0);
    }
    return 0;
  }, [appliedServiceCredits, servicesIdMap, serviceCreditIdToServiceIdMap]);

  useEffect(() => {
    const newRemainingToPay = Math.max(
      checkout.total - otherTenderTotal - futureUseCreditsApplied - referralValue - +accountCredit,
      0
    );
    setRemainingToPay(newRemainingToPay);
  }, [checkout, otherTenderTotal, futureUseCreditsApplied, referralValue, accountCredit]);

  useEffect(() => {
    if (checkout) {
      setRemainingToPay(checkout.toPay);
    }
  }, [checkout]);

  const hasGaldermaProducts =
    servicesIdMap &&
    lineItems?.some((lineItem) => {
      const sku = servicesIdMap[lineItem.serviceId]?.galdermaSku;
      return sku && sku !== '{}' && lineItem.currentUseQuantity > 0;
    });

  // GALDERMA INTEGRATION:
  // const [galdermaLocations, setGaldermaLocations] = useState<string[]>([]);
  // const enableGaldermaConfiguration =
  //   hasGaldermaProducts && !isLoadingCheckout && serviceVisit?.paymentStatus === PAYMENT_STATUS_UNPAID;
  // const {
  //   data: certificates,
  //   patient: galdermaPatient,
  //   productsConfigured,
  //   products: galdermaProducts,
  //   isFetching: isFetchingCertificates,
  //   // Temporarily disabled
  // } = useAspireCertificates(+serviceVisitId, galdermaLocations, false && enableGaldermaConfiguration);

  // // Temporarily disabled
  // const enableCertificatesUsed = !!productsConfigured?.length || serviceVisit?.paymentStatus === PAYMENT_STATUS_PAID;
  // const {
  //   data: {
  //     certificates: certificatesUsed,
  //     products: productsUsed,
  //     value: aspireValueUsed
  //   },
  //   isFetching: isFetchingCertificatesUsed,
  // } = useAspireCertificatesUsed(+serviceVisitId, false && enableCertificatesUsed);

  // const onChangeAspireLocation = (newAspireSkus: string[]) => {
  //   setGaldermaLocations(newAspireSkus);
  // };

  const searchParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (checkout) {
      setAccountCredit(checkout.accountCreditUsed);
      setNote(checkout.note);
    }
  }, [checkout]);

  useEffect(() => {
    if (transactionInProgress && checkout.transactionStatus === SQUARE_STATUS.OK) {
      setTransactionInProgress(false);
      clearInterval(pollInterval);
      setPollInterval(undefined);
      refetchServiceCredits();
      if (permissions?.length > 0 && hasAccessTo(READ_REFERRAL, permissions)) {
        refetchReferrals();
      }
    }
  }, [checkout, pollInterval]);

  useEffect(() => {
    if (transactionInProgress && checkout.transactionStatus === SQUARE_STATUS.OK) {
      setTransactionInProgress(false);
      clearInterval(pollInterval);
      setPollInterval(undefined);
    }
    if (refundInProgress && checkout.transactionStatus !== SQUARE_STATUS.OK) {
      setRefundInProgress(false);
      clearInterval(pollInterval);
      setPollInterval(undefined);
    }
  }, [checkout, pollInterval, refundInProgress]);

  const isAccountChargeServiceVisit = serviceVisit?.visitType === ACCOUNT_CHARGE_VISIT_TYPE;

  const lineItemIdToDiscountsMap = useMemo((): Record<number, IDiscount[]> => {
    if (discountsData?.length) {
      return discountsData.reduce(
        (obj: any, discountData: CheckoutLineItemDiscount) =>
          Object.assign(obj, { [discountData.checkoutLineItem]: discountData.discounts }),
        {}
      );
    }
    return {};
  }, [discountsData]);

  const serviceGroupIdMap = useMemo((): Record<number, IServiceGroup> => {
    if (serviceGroups?.length) {
      return serviceGroups.reduce(
        // @ts-ignore
        (obj: any, serviceGroup: IServiceGroup) => Object.assign(obj, { [serviceGroup.id]: serviceGroup }),
        {}
      );
    }
    return {};
  }, [serviceGroups]);

  const serviceUsingDynamicPricingMap = useMemo((): Record<number, boolean> => {
    if (patientServices.length && serviceGroups?.length && serviceGroupIdMap) {
      // @ts-ignore
      return patientServices.reduce(
        (obj: any, service: IServices) =>
          Object.assign(obj, { [service.id]: serviceGroupIdMap[service.serviceGroupId]?.useDynamicPricing || false }),
        {}
      );
    }
    return {};
  }, [patientServices, serviceGroups, serviceGroupIdMap]);

  /* eslint-disable no-unused-expressions */
  const onClickBackButton = () => {
    if (userType === PRACTITIONER_ROLE) {
      serviceVisit?.visitType === IN_PERSON_VISIT_TYPE
        ? history.push(`/patient/${patientId}/newServiceVisit/${serviceVisitId}`)
        : history.push(`/patient/${patientId}/retailCheckout`);
    } else {
      serviceVisit?.visitType === IN_PERSON_VISIT_TYPE
        ? history.push(`/patient/${patientId}/newServiceVisit/${serviceVisitId}`)
        : history.push(`/patient/${patientId}`);
    }
  };

  const [transactionInProgress, setTransactionInProgress] = useState<boolean>(false);
  const [openPayStagingModal, setOpenPayStagingModal] = useState<boolean>(false);

  const getOverlay = {
    opacity: transactionInProgress || checkout.transactionStatus === SQUARE_STATUS.OK ? '0.5' : undefined,
    pointerEvents: transactionInProgress || checkout.transactionStatus === SQUARE_STATUS.OK ? 'none' : undefined,
  } as object;

  const serviceCounts = useMemo(
    (): Record<number, number> =>
      lineItems.reduce(
        (obj, lineItem) => Object.assign(obj, { [lineItem.serviceId]: lineItem.currentUseQuantity }),
        {}
      ) || {},
    [lineItems]
  );

  const handleSetAccountCredit = (newAccountCredit: number | string | undefined) => {
    // @ts-ignore
    setAccountCredit(newAccountCredit);
    // @ts-ignore
    updateAccountCredit(newAccountCredit);
  };

  const handleSetNote = (newNote: string) => {
    setNote(newNote);
    updateNote(newNote);
  };

  const handleUpdateCheckoutNote = async (newNote: string) => {
    await updateCheckout({ note: newNote });
  };

  const updateNote = useCallback(debounce(handleUpdateCheckoutNote, 500), []);

  const handleUpdateAccountCredit = async (newAccountCredit: string | number) => {
    try {
      await updateCheckout({ accountCreditUsed: +newAccountCredit });
    } catch (error) {
      if (error.message.includes(CHECKOUT_ACCOUNT_CREDIT_ERROR)) {
        setAccountCreditErrorModalOpen(true);
      }
      setAccountCredit(checkout?.accountCreditUsed || 0);
    }
  };

  const updateAccountCredit = useCallback(debounce(handleUpdateAccountCredit, 500), []);

  const handleRefetch = async () => {
    await refetchLineItems();
    if (fetchDiscounts) {
      await refetchDiscounts();
    }
    await refetchCheckoutTenders();
    await refetchCheckout();
  };

  const resetTenderAndCredit = () => {
    setResetCredits(true);
    setResetTenders(true);
  };

  const disableLoyaltyPrograms = useMemo((): boolean => {
    if (lineItems && checkout) {
      const discounts = lineItems?.map((lineItem) => lineItem.checkoutLineItemDiscounts)?.flat() || [];
      const onlyFutureUseCredit =
        lineItems.reduce(
          (currentUseQuantity: number, lineItem: ILineItem) => currentUseQuantity + lineItem.currentUseQuantity,
          0
        ) === 0;

      if (onlyFutureUseCredit || (discounts.length > 0 && checkout.total < LIMIT_FOR_DISCOUNT)) {
        return true;
      }
    }
    return false;
  }, [lineItems, checkout]);

  // Called after applying discounts to check if we need to remove tenders
  const handleCheckTenders = async () => {
    const tenders = [CARE_CREDIT_TENDER, ALLE_WITH_CHERRY_TENDER, PATIENTFI_TENDER];
    checkoutTenders?.forEach((tender: ITender) => {
      if (tenders.includes(tender.tenderType) && checkout.total < LIMIT_FOR_DISCOUNT) {
        applyTender({
          value: 0,
          voucher: '',
          tenderType: tender.tenderType,
        });
      }
    });

    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('checkout.discounts_removed_other_tenders'),
        type: 'info',
      },
    });
  };

  const openSquarePOS = (): void => {
    const amount = `${Math.round(checkout.toPay * 100)}`;
    const state: string = JSON.stringify({ serviceVisitId });
    const ids: string = `${patientId}-${serviceVisitId}`;
    const squareNotes: string = checkout.note ? `${ids} - ${checkout.note}` : ids;
    const dataParameter = getDataParameter(amount, squareNotes, state);
    const squareUrl = `square-commerce-v1://payment/create?data=${encodeURIComponent(JSON.stringify(dataParameter))}`;
    logSentryMessageInfo('OPEN SQUARE POS DATA', { data: { providerEmail, squareUrl } });
    pollCheckout();

    window.location.href = squareUrl;
    setTransactionInProgress(true);
    setProccessingPaymentInSquare(true);
  };

  const chargeForServiceVisit = async ({
    cardId,
    cashAmount,
    total,
    tipAmount = 0,
    onError,
    payWithStripe = false,
  }: {
    cardId?: string;
    cashAmount?: number;
    total?: number;
    tipAmount?: number;
    onError?: () => void;
    payWithStripe?: boolean;
  }) => {
    try {
      setTransactionInProgress(true);
      if (total && payWithStripe) {
        await payWithStripeTerminalMutation.mutate(
          {
            total,
            patientId,
            tipAmount,
            isFeeCharge: FEE_RELATED_CHARGE_TYPES.includes(checkout.chargeType),
          },
          {
            onError: () => {
              onError?.();
            },
          }
        );
        if (!IS_PROD) {
          confirmPaymentWithStripeTerminalMutation.mutate(undefined, {
            onError: () => {
              onError?.();
            },
          });
        }
        pollCheckout();
      } else {
        await payWithCardOrCashMutation.mutate(
          {
            cardId,
            cashAmount,
            patientId,
            tipAmount,
            isFeeCharge: FEE_RELATED_CHARGE_TYPES.includes(checkout.chargeType),
          },
          {
            onError: () => {
              onError?.();
            },
          }
        );
      }
      pollCheckout();
    } finally {
      setTransactionInProgress(false);
    }
  };

  const pollCheckout = () => {
    if (pollInterval) {
      clearInterval(pollInterval);
    }
    const interval = setInterval(refetchCheckout, 3000);
    setPollInterval(interval);
  };

  const payZeroBalance = async () => {
    setTransactionInProgress(true);
    await payZeroBalanceMutation.mutate();
    await refetchCheckout();
    setTransactionInProgress(false);
  };

  const payStaging = (): void => {
    try {
      setTransactionInProgress(true);
      payStagingMutation.mutate();
      pollCheckout();
      setOpenPayStagingModal(false);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      setTransactionInProgress(false);
    }
  };

  const showEditUnitPrice = checkout.transactionStatus !== SQUARE_STATUS.OK && enabledEditUnitPrice;
  const isFeeCharge = FEE_RELATED_CHARGE_TYPES.includes(checkout.chargeType);

  return (
    <Container className={styles.root}>
      <NavigationBar title="Checkout" onBackButtonClick={onClickBackButton} />
      <Container className={styles.order}>
        {loading ? (
          <div className={styles.orderRoot}>
            <MultipleSkeleton addPosition={false} width="100%" margin="0" />
          </div>
        ) : (
          <>
            <div className={styles.orderRoot}>
              <CheckoutHeader
                serviceVisit={serviceVisit}
                patientId={patientId}
                transactionStatus={checkout.transactionStatus}
                processedInSquare={checkout.processedInSquare}
                squareErrorCode={checkout.squareErrorCode || ''}
                lastCheckoutTransactionCreatedAt={checkout.lastCheckoutTransactionCreatedAt}
                toPay={checkout.toPay}
                amountPaid={checkout.amountPaid}
                hasPartialRefund={checkout.hasPartialRefund}
              />
              <div style={getOverlay}>
                <LineItemTable
                  patient={patient}
                  serviceUsingDynamicPricingMap={serviceUsingDynamicPricingMap}
                  lineItemIdToDiscountsMap={lineItemIdToDiscountsMap}
                  lineItems={lineItems}
                  servicesIdMap={servicesIdMap}
                  handleRefetch={handleRefetch}
                  serviceVisitId={serviceVisitId}
                  handleCheckTenders={handleCheckTenders}
                  resetTenderAndCredit={resetTenderAndCredit}
                  showEditUnitPrice={showEditUnitPrice}
                  disableDiscounts={isFeeCharge}
                />
                <DiscountsSubTotal
                  subTotal={formatNumber(checkout.subtotal ?? 0)}
                  discounts={formatNumber(checkout.discounts ?? 0)}
                  total={formatNumber(checkout.total ?? 0)}
                />
                {isLoadingOtherTenders ? (
                  <div style={{ height: '500px' }}>
                    <MultipleSkeleton length={8} addPosition={false} width="100%" margin="0" />
                  </div>
                ) : (
                  <>
                    {!isFeeCharge && (
                      <FutureCredits
                        serviceCounts={serviceCounts}
                        servicesIdMap={servicesIdMap}
                        serviceToOverriddenPriceMap={serviceToOverriddenPriceMap}
                        appliedServiceCredits={appliedServiceCredits}
                        serviceCredits={serviceCredits}
                        serviceVisitId={serviceVisitId}
                        isCheckoutPaid={checkout.transactionStatus === SQUARE_STATUS.OK}
                        refetchAppliedServiceCredits={refetchAppliedServiceCredits}
                        resetCredits={resetCredits}
                        setResetCredits={setResetCredits}
                      />
                    )}
                    {!isFeeCharge && (
                      <OtherTenders
                        serviceVisitId={serviceVisitId}
                        tenders={checkoutTenders}
                        patient={patient}
                        disableLoyaltyPrograms={disableLoyaltyPrograms}
                        referralCredits={referralCredits || []}
                        appliedReferralCredits={appliedReferralCredits || []}
                        isLoadingAppliedReferred={isFetchingAppliedReferred}
                        hasGaldermaProducts={hasGaldermaProducts}
                        refetchAppliedReferralCredits={refetchAppliedReferralCredits}
                        remainingToPay={remainingToPay}
                        setOtherTenderTotal={setOtherTenderTotal}
                        resetTenders={resetTenders}
                        setResetTenders={setResetTenders}
                        showReferralSection={hasAccessTo(READ_REFERRAL, permissions)}
                        isPractitioner={userType === PRACTITIONER_ROLE}
                      />
                    )}
                    {/* // certificates={certificates}
                      // certificatesUsed={certificatesUsed}
                      // productsUsed={productsUsed}
                      // aspireValueUsed={aspireValueUsed}
                      // isFetchingCertificatesUsed={isFetchingCertificatesUsed}
                      // isFetchingCertificates={isFetchingCertificates}
                      // galdermaPatient={galdermaPatient}
                      // onChangeAspireLocation={onChangeAspireLocation}
                      // galdermaProducts={galdermaProducts}
                      // patientServices={patientServices}
                      // lineItems={lineItems} */}
                    {!isFeeCharge && (
                      <AccountCredit
                        showCurrentBalance={checkout.transactionStatus !== SQUARE_STATUS.OK}
                        accountCreditUsed={accountCredit}
                        onChange={handleSetAccountCredit}
                        currentBalance={patient?.currentBalance}
                        toPay={remainingToPay}
                      />
                    )}
                    {isFeeCharge && <br />}
                    <AddNote note={note} setNote={handleSetNote} />
                    {hasAccessTo(READ_REFERRAL, permissions) && (
                      <ReferralsSelector
                        showReferredLabelButton
                        showFormIfCannotReferrer={false}
                        patientId={patientId}
                      />
                    )}
                  </>
                )}
                <RemainingToPay
                  openSquarePOS={IS_PROD ? openSquarePOS : () => setOpenPayStagingModal(true)}
                  checkout={checkout}
                  accountCreditUsed={+accountCredit || 0}
                  checkoutTenders={checkoutTenders || []}
                  referralValue={referralValue}
                  patient={patient}
                  loading={loading || isLoadingOtherTenders || isFetchingCheckout}
                  handlePayment={chargeForServiceVisit}
                  lineItems={lineItems}
                  updateCheckout={updateCheckout}
                  payZeroBalance={payZeroBalance}
                  isPractitioner={userType === PRACTITIONER_ROLE}
                  medspaLocationId={serviceVisit?.medspaLocationId}
                />
              </div>
            </div>
            <Footer
              patient={patient}
              transactionStatus={checkout?.transactionStatus}
              refundInProgress={refundInProgress}
              setRefundInProgress={setRefundInProgress}
              isServiceVisitOpen={serviceVisit?.opened}
              processedInSquare={checkout?.processedInSquare}
              isAccountChargeServiceVisit={isAccountChargeServiceVisit}
              pollCheckout={pollCheckout}
            />
          </>
        )}
        <ProccessingPaymentInSquareModal proccessingPaymentInSquare={proccessingPaymentInSquare} />
        <AccountCreditErrorModal
          open={accountCreditErrorModalOpen}
          onClose={() => setAccountCreditErrorModalOpen(false)}
        />
        <ConfirmModal
          title="Square SANDBOX"
          contentText="do you want to create a payment in the sandbox environment?"
          openDialog={openPayStagingModal}
          setOpenDialog={setOpenPayStagingModal}
          onClickConfirm={payStaging}
          confirmText="Pay"
          onClickCancel={() => null}
        />
      </Container>
    </Container>
  );
};

export default ServiceVisitCheckoutContainer;
