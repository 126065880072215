/* eslint-disable max-len */
import { FieldOption, StripeElementsOptionsMode, StripePaymentElementOptions } from '@stripe/stripe-js';
import { StripeElementsState } from 'src/types/Stripe';

export const STRIPE_REFUND_FEE_MESSAGE = 'Note: Fees associated with processing Stripe payments will not be refunded.';
export const STRIPE_CARD_CANNOT_BE_DELETED_MESSAGE =
  'This card is currently being used for an in-progress order. You cannot delete it until the order is fully processed or canceled.';
export const ERROR_PREFIX = 'Stripe initialization failed:';
export const ERROR_INITIALIZATION = 'Stripe has not been properly initialized';
export const ERROR_CREATE_PAYMENT_METHOD = 'An error occurred while creating the Stripe elements';
export const ERROR_ELEMENTS = 'An error occurred while creating the Stripe elements';
export const ERROR_PAYMENT_ELEMENT = 'Payment element container (#payment-element) not found in the DOM';
export const ERROR_UNKNOWN = 'Unknown error occurred';
export const ERROR_SAVE_PAYMENT_METHOD = 'Failed to save payment method';
export const ERROR_LOAD_PAYMENT_FORM = 'Unable to load payment form';
export const ERROR_STRIPE_KEY = 'Stripe public key is not set';

export const DEFAULT_BUTTON_TEXT = 'Save Payment Method';
export const DEFAULT_BUTTON_ERROR = 'Error encountered';

export const DEFAULT_ADDRESS_OPTIONS = { country: 'US' };

export const DEFAULT_ELEMENT_OPTIONS: StripeElementsOptionsMode = {
  paymentMethodCreation: 'manual',
  currency: 'usd',
};

export const DEFAULT_ELEMENT_OPTIONS_MODE: StripeElementsOptionsMode = {
  ...DEFAULT_ELEMENT_OPTIONS,
  paymentMethodTypes: ['card'],
  mode: 'setup',
};

export const DEFAULT_BILLING_DETAILS: {
  name: FieldOption;
  address: {
    country: FieldOption;
    postalCode: FieldOption;
  };
} = {
  name: 'auto',
  address: {
    country: 'never',
    postalCode: 'auto',
  },
};

export const DEFAULT_PAYMENT_ELEMENT_OPTIONS: StripePaymentElementOptions = {
  fields: {
    billingDetails: DEFAULT_BILLING_DETAILS,
  },
  layout: {
    type: 'tabs',
    defaultCollapsed: false,
  },
};

export const DEFAULT_ELEMENTS_STATE: StripeElementsState = {
  stripe: null,
  elements: null,
  isLoading: true,
  error: null,
};

export enum STRIPE_STATUS {
  FAILED = 'failed',
  OK = 'ok',
  PENDING = 'pending',
}
