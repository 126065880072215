import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { CardContent } from '@material-ui/core';
import SavePaymentMethod from 'src/components/common/Payment/SavePaymentMethodCustomer';
import { CreditCardCard } from './CreditCardCard';
import { ConfirmCreditCardActionModal } from './ConfirmCreditCardActionModal';
import { useStyles } from '../index.styles';
import { dispatch } from '../../../rematch';
import { PATIENT_INFO } from '../../../constants/reactQuery.keys';
import Patients from '../../../services/Patients';
import { usePractitionerInfo } from '../../../hooks/queries/usePractitioners';
import { Card } from '../../../components/common/card';
import { ShortMultipleSkeleton } from '../../../components/common/LoadingSkeleton';

interface ManageCardsProps {
  patientId: string;
  afterCardAdded?: () => void;
}

export const ManageCards = ({ patientId, afterCardAdded }: ManageCardsProps) => {
  const classes = useStyles();
  const [openConfirmActionModal, setOpenConfirmActionModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<'added' | 'removed'>('added');
  // There isn't an easy way to clear squares credit card form so use a seed key that changes
  // to reload the component and clear the form.
  const [seed, setSeed] = useState(1);
  const MAX_RETRY = 3;
  const {
    data: patient,
    isLoading,
    refetch,
  } = useQuery([PATIENT_INFO, patientId], () => Patients.getPatientInfo(patientId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const subscription = patient?.basicInfo?.subscription;

  const { practitionerInfo } = usePractitionerInfo(patient?.contactInfo?.practitioner?.id);

  const fetchPatientCards = async (callback: () => void, retryAttempt = 0) => {
    const patientResp = await refetch();
    if (patientResp?.data?.basicInfo.creditCardOnFile && patientResp?.data?.basicInfo?.creditCards.length) {
      setConfirmAction('added');
      if (afterCardAdded) {
        afterCardAdded();
      } else {
        setOpenConfirmActionModal(true);
      }
      callback();
      // @ts-ignore
      setSeed(crypto.randomUUID());
      // When adding a new customer in Square, there may be a delay in fetching that customers credit card info,
      // which is why need to retry fetching the customer with backoff of 3 seconds * # of tries.
    } else if (retryAttempt <= MAX_RETRY) {
      setTimeout(() => fetchPatientCards(callback, retryAttempt + 1), retryAttempt * 3000);
    } else {
      callback();
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: 'Something went wrong, please refresh and try again.',
          type: 'error',
        },
      });
    }
  };

  const onCardAdded = async (cardId: string, cb: () => void) => {
    await fetchPatientCards(cb, 0);
  };

  const onCardRemoved = async () => {
    refetch();
    await dispatch.patient.fetchBasicInfo({ patientId: parseInt(patientId, 10) });
    setConfirmAction('removed');
    setOpenConfirmActionModal(true);
  };

  const handleMakeCardDefault = async (cb: () => void) => {
    refetch();
    await dispatch.patient.fetchBasicInfo({ patientId: parseInt(patientId, 10) });
    cb();
  };

  const renderCreditCards = (card: any) => (
    <CreditCardCard
      key={card.id}
      card={card}
      subscription={subscription}
      patientId={patientId}
      onCardRemoved={onCardRemoved}
      handleMakeCardDefault={handleMakeCardDefault}
      isDefault={card.id === patient?.basicInfo?.squareDefaultCreditCardId}
    />
  );

  const renderCreditCard = () => (
    <SavePaymentMethod
      key={seed}
      locationId={practitionerInfo?.squareLocationId ?? ''}
      patientId={patientId}
      onCardAdded={onCardAdded}
    />
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className={classes.card}>
          <ShortMultipleSkeleton length={3} />
        </CardContent>
      </Card>
    );
  }
  return (
    <>
      <div className={classes.cardTitle}>Your saved credit and debit cards</div>
      <div className={classes.cardsContainer}>
        {patient?.basicInfo?.creditCards?.length ? (
          patient?.basicInfo?.creditCards?.map((card: any) => renderCreditCards(card))
        ) : (
          <div className={classes.emptyCreditCards}>
            You have no saved cards. Please enter your card details below to add one.
          </div>
        )}
      </div>
      <div className={classes.cardTitle}>Add a payment method</div>
      {renderCreditCard()}
      <ConfirmCreditCardActionModal
        open={openConfirmActionModal}
        handleCloseCallback={() => setOpenConfirmActionModal(false)}
        confirmAction={confirmAction}
      />
    </>
  );
};
