/* eslint-disable max-len */
export const PHOTO_ID_FORM_DESCRIPTION =
  'A government issued photo ID is required for regulatory compliance. Thank you for your cooperation.';
export const DOCUMENT_SIGNATURE_FORM_DESCRIPTION =
  'In order to sign the documents digitally we need to use your real signature.';
export const ADDRESS_FORM_DESCRIPTION =
  'Please provide an address where we can ship medical supplies. We are only able to ship medical supplies to a business address. If you are planning to practice without a physical location, please coordinate with a local business that is willing to receive packages on your behalf.';
export const PROVIDER_ENTITY_FORM_DESCRIPTION =
  'Please enter your business name exactly as it appears on your documents.';
export const PAYMENT_METHOD_FORM_DESCRIPTION =
  'You can change your card at anytime in your MedSpa Admin settings. The card will be used for any products or services that you purchase through Portrait’s network.';
export const PAYMENT_MANAGEMENT_SETUP_FORM_DESCRIPTION =
  'This setup will allow you to charge patients using the Payment Terminal or stored cards on file, as well as pay for services such as inventory purchases or other Portrait-provided services.';
export const BANK_ACCOUNT_DESCRIPTION =
  'This information is used for a variety of items like setting up your rewards portals with supplier partners, depositing customer payments, and handling purchases.';
export const POSITION_DESCRIPTION = 'Your title. For instance, "Lead Aesthetic Injector" or "Office Manager".';
export const REAL_ESTATE_DOCUMENT_HELPER_TEXT =
  "Please sign the following document. This real estate information is used to set up your ship-to's with supplier partners on our national MSO contracts. Please make sure the business name (DBA) you enter matches your other documents exactly to speed up the setup process!";
export const INVENTORY_AGREEMENT_DESCRIPTION =
  'All orders on Portrait-affiliated supplier accounts must be placed directly through Portrait’s ordering system, or you can incur substantial automatic charges in excess of $500. While Portrait accounts typically receive special benefits including education, contract pricing, and sample products, if you prefer to work directly with suppliers, you are able to create independent accounts by contacting the supplier directly.';

export const PENDING_STEPS_FOR_GFE_WARNING =
  "As soon as your Medical Director information and forms have been completed, you'll be able to request a GFE. These are used by Portrait to maintain compliance.";

export const PENDING_STEPS_FOR_INVENTORY_WARNING = `As soon as the following information and forms have been completed, you'll be able to place your first order. These are used by Portrait to maintain compliance.`;
