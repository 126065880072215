import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { EHRTextField, EHRTextFieldProps } from './EHRTextField';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  ehrTimePicker: {
    height: '100%',
    '& input': {
      minHeight: '30px',
      minWidth: '100px',
    },
  },
}));

interface EHRTimePickerProps extends EHRTextFieldProps {}

export default function EHRTimePicker(props: EHRTimePickerProps) {
  const classes = useStyles();
  const { dataCy, className, value: propsValue, ...restProps } = props;
  const value = useMemo(() => {
    if (typeof propsValue !== 'string') {
      return propsValue;
    }

    if (propsValue.toUpperCase().includes('AM') || propsValue.toUpperCase().includes('PM')) {
      return moment(`${moment().format('YYYY-MM-DD')} ${propsValue}`).format('HH:mm');
    }
    return propsValue;
  }, [propsValue]);

  return (
    <EHRTextField
      {...restProps}
      dataCy={dataCy}
      value={value}
      type="time"
      className={classNames(classes.ehrTimePicker, className)}
      inputProps={{
        step: 300, // 5 min
      }}
    />
  );
}
