import React, { useEffect, useMemo, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import ISubscription from 'src/interfaces/ISubscription';
import ISquareSubscription from '../../../interfaces/ISquareSubscription';
import { useStyles } from './MembershipDetails.styles';
import { BEAUTIBANK_ERROR_MESSAGE_MAP, BEAUTY_BANK_SUBSCRIPTION_STATUS } from '../../../constants/beautyBank.constants';
import formatDate, { formatMonthWithZero } from '../../../utils/formatDate';
import CreditCardIcon from '../../../components/PatientProfile/CreditCards/CreditCardIcon';
import { ISquareCard } from '../../../interfaces/ISquareCard.interfaces';
import { useUpdateBeautiBankSubscription } from '../../../hooks/mutations/useUpdateBeautiBankSubscription';

interface PendingMembershipDetailsProps {
  patientId: string;
  subscription: ISquareSubscription;
  refetch: () => void;
  accountBalance: number;
  customerCards: ISquareCard[];
  metadataPdfUrl: string | undefined;
  setCustomerSubscription: (newSub: ISquareSubscription | ISubscription | undefined) => void;
  paymentProcessor: string;
}

export const PendingMembershipDetails = ({
  patientId,
  subscription,
  refetch,
  accountBalance,
  customerCards,
  metadataPdfUrl,
  setCustomerSubscription,
  paymentProcessor,
}: PendingMembershipDetailsProps) => {
  const classes = useStyles();
  const updateSubscriptionMutation = useUpdateBeautiBankSubscription(patientId);
  const [isPolling, setIsPolling] = useState<boolean>(true);

  const card = useMemo(
    () => customerCards.find((creditCard) => creditCard.id === subscription.cardId),
    [customerCards, subscription]
  );

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (subscription) {
      let currentPoll = 1;
      intervalId = setInterval(() => {
        if (currentPoll <= 20 && subscription.status !== BEAUTY_BANK_SUBSCRIPTION_STATUS.ACTIVE) {
          refetch();
          currentPoll += 1;
        } else {
          setIsPolling(false);
          clearInterval(intervalId);
        }
      }, 15000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [subscription, refetch]);

  const acknowledgeError = async () => {
    if (paymentProcessor === STRIPE_PAYMENT_PROCESSOR) {
      console.log('[TODO] stripe implementation');
      return;
    }
    await updateSubscriptionMutation.mutateAsync({
      acknowledgedLastSquareError: true,
    });
    setCustomerSubscription(undefined);
  };

  return (
    <div className={classes.detailsContainer}>
      <Typography variant="h5">Manage Membership</Typography>
      <Typography className={classes.subtitle}>
        {subscription.lastSquareError ? (
          <Typography className={classes.errorMessage}>
            <ErrorOutlineIcon className={classes.errorMsgIcon} />
            {BEAUTIBANK_ERROR_MESSAGE_MAP[subscription.lastSquareError] ||
              'Something went wrong with processing your subscription payment,' +
                ' please try again later or with a different card.'}
          </Typography>
        ) : (
          <div style={{ color: '#FF8C00' }}>
            Processing Payment...
            {!isPolling && subscription.status !== BEAUTY_BANK_SUBSCRIPTION_STATUS.ACTIVE && (
              <div>
                <button className={classes.refreshButton} type="button" onClick={() => window.location.reload()}>
                  Refresh
                </button>
              </div>
            )}
          </div>
        )}
      </Typography>
      <div className={classes.detailBlock}>
        <div className={classes.detailWithHeader}>
          <span className={classes.detailHeader}>Start Date</span>
          <span>{formatDate(subscription.createdAt, 'll')}</span>
        </div>
        <div className={classes.detailWithHeader}>
          <span className={classes.detailHeader}>End Date</span>
        </div>
      </div>
      <hr />
      <div className={classes.detailBlock}>
        <div>Account Credits (Bank Balance)</div>
        <div>${accountBalance}</div>
      </div>
      <hr />
      <div className={classes.detailBlock}>
        <div className={classes.detailWithHeader}>
          <span className={classes.detailHeader}>Payment Method On File</span>
          <span className={classes.detailsCard}>
            <span className={classes.cardIcon}>
              <CreditCardIcon cardBrand={card?.cardBrand} />
            </span>
            ending in {card?.last4} expiring {formatMonthWithZero(card?.expMonth)}/{card?.expYear}
          </span>
        </div>
      </div>
      <div className={classes.detailBlock}>
        <a href={metadataPdfUrl} target="_blank" rel="noreferrer">
          <Button className={classes.agreementButton} type="button">
            View Agreement
          </Button>
        </a>
        {subscription.lastSquareError && (
          <Button className={classes.agreementButton} type="button" onClick={acknowledgeError}>
            Try again
          </Button>
        )}
      </div>
    </div>
  );
};
