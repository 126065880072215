import { withStyles, makeStyles, Button } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  containerDialog: {
    width: '450px',
    height: '251px',
  },
  title: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '24px !important',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    textAlign: 'center',
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    paddingLeft: '15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '30px',
    paddingBottom: '30px',
  },
  buttonRight: {
    width: '120px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#fbdada',
    color: '#000000',
  },
  buttonLeft: {
    width: '120px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#e7eeed',
  },
  colorWhite: {
    color: '#ffffff',
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  secondaryButton: {
    color: '#12574d',
    backgroundColor: 'white',
    border: '1px solid #12574d',
    marginRight: '20px',
  },
  refundTooltip: {
    marginLeft: '10px',
    cursor: 'pointer',
  },
  refundButtonContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const CustomButton = withStyles(() => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#12574d',
    minWidth: '159px',
    borderRadius: '4px',
    padding: '15px',
    '&:hover': {
      backgroundColor: '#12574d',
    },
  },
  disabled: {
    opacity: 0.7,
    color: 'black !important',
  },
}))(Button);

export const RefundButton = withStyles(() => ({
  root: {
    backgroundColor: '#fbdada',
    color: '#000000',
    width: '159px',
    borderRadius: '4px',
    padding: '15px',
    '&:hover': {
      backgroundColor: '#fbdada',
      color: '#000000',
    },
  },
  disabled: {
    opacity: 0.5,
    color: '#000000 !important',
  },
}))(Button);

export const secondaryButtonStyles = {
  marginRight: '20px',
  color: '#12574d',
  backgroundColor: 'white',
  border: '1px solid #12574d',
};
