import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TableCell,
  TableRow,
  Typography,
  Select,
} from '@material-ui/core';
import { sortBy } from 'lodash';
import { Search as SearchIcon } from '@material-ui/icons';
import { InfiniteData } from 'react-query';
import { PossibleTransactionResponse } from 'src/hooks/queries/usePractitionersDashboard';
import { useSelector } from 'react-redux';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { useMedspaLocations } from 'src/hooks/queries/medspaAdmins/useMedspaLocations';
import { SelectedLocation } from 'src/components/DashboardMedspaAdmin/Earnings/EarningsTab';
import { useStyles } from './practitionerDashboard.styles';
import { PortraitTable } from '../../../common/TableNew/Table';
import { IPossibleTransaction } from '../../../../interfaces/ITransaction.interfaces';
import { formatCurrency } from '../../../../utils/formatNumber';
import { SelectCustom } from '../../../DashboardAdministrator/Inventory/supplyReceipt.styles';
import { MEDSPA_ADMIN_ROLE, longMonths } from '../../../../constants/general.constants';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';

const TRANSACTION_COLUMNS = [
  { id: 'customer_id', title: 'MRN' },
  { id: 'customer_name', title: 'Patient' },
  { id: 'create_at', title: 'Date' },
  { id: 'services_amount', title: 'Service Amt.' },
  { id: 'products_amount', title: 'Retail Products Amt.' },
  { id: 'tips', title: 'Tips' },
  { id: 'discounts', title: 'Discount' },
  { id: 'status', title: 'Status' },
];

const FlexTransactionRow = ({ checkoutData }: { checkoutData: IPossibleTransaction }) => {
  const classes = useStyles();

  const navigateToCheckout = () => {
    const newWindow = window.open(checkoutData.ehrUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    <TableRow key={checkoutData.id} onClick={navigateToCheckout} className={classes.transactionRow}>
      <TableCell>{checkoutData.customerId}</TableCell>
      <TableCell>{checkoutData.customerName}</TableCell>
      <TableCell>{moment(checkoutData.createdAt).utcOffset('-0700').format('MM/DD/YYYY hh:mm A')} PDT</TableCell>
      <TableCell>{formatCurrency(checkoutData.servicesAmount)}</TableCell>
      <TableCell>{formatCurrency(checkoutData.productsAmount)}</TableCell>
      <TableCell>{formatCurrency(checkoutData.tips || 0)}</TableCell>
      <TableCell>{formatCurrency(checkoutData.discounts || 0)}</TableCell>
      <TableCell>
        <span className={checkoutData.processedStatus ? classes.processed : classes.unprocessed}>
          {checkoutData.processedStatus ? 'Processed' : 'Pending'}
        </span>
      </TableCell>
    </TableRow>
  );
};

export interface IFlexTransaction {
  transactions: InfiniteData<PossibleTransactionResponse>;
  oldestTransactionRecords: InfiniteData<PossibleTransactionResponse>;
  searchQuery: any;
  setSearchQuery: (query: any) => void;
  selectedMedspaLocation: SelectedLocation | null;
  setSelectedMedspaLocation: (location: SelectedLocation | null) => void;
  isLoadingOldestRecord: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  selectedDate?: Moment;
}

export const FlexTransactions = ({
  transactions,
  oldestTransactionRecords,
  isLoadingOldestRecord,
  searchQuery,
  selectedMedspaLocation,
  setSelectedMedspaLocation,
  setSearchQuery,
  isLoading,
  isFetching,
  isError,
  hasNextPage,
  fetchNextPage,
  selectedDate,
}: IFlexTransaction) => {
  const { roleName } = useSelector(({ auth }: any) => auth);
  const classes = useStyles();
  const [searchText, setSearchText] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [year, setYear] = useState<number | string | null>(null);
  const [month, setMonth] = useState<number | string | null>(null);
  const {
    data: { medspaLocations = [] },
  } = useMedspaLocations();

  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;

  useEffect(() => {
    if (isMedspaAdmin) {
      const selectedYear = selectedDate?.format('YYYY');
      const selectedMonth = selectedDate?.format('M');
      setSearchQuery({ ...searchQuery, ...{ year: selectedYear, month: selectedMonth, search: searchText, status } });
    } else {
      setSearchQuery({ ...searchQuery, ...{ year, month, search: searchText, status } });
    }
  }, [year, month, searchText, status, selectedDate]);

  const data = transactions?.pages.map(({ checkouts = [] }) => checkouts).flat() || [];
  const oldestRecord = oldestTransactionRecords?.pages.map(({ checkouts = [] }) => checkouts).flat()[0];

  const currentYear = new Date().getFullYear();
  const startYear = oldestRecord ? moment(oldestRecord.createdAt).year() : currentYear;
  const availableYears = useMemo(() => {
    const years: string[] = [];
    let current = currentYear;
    while (current >= startYear) {
      years.push(current.toString());
      current -= 1;
    }
    return years;
  }, [currentYear, startYear]);

  const handleYearChange = (newYear: string | null) => {
    if (!newYear && month) {
      setMonth(null);
    }
    // eslint-disable-next-line no-unused-expressions
    newYear ? setYear(newYear) : setYear(null);
  };

  if (isLoadingOldestRecord) {
    return <MultipleSkeleton addPosition={false} />;
  }

  return (
    <div className={classes.flexTransactions}>
      <Typography variant="h6" className={classes.title}>
        Transactions
      </Typography>
      <div className={classes.filterContainer}>
        <FormControl size="small" variant="outlined">
          <InputLabel htmlFor="search" color="secondary">
            Search
          </InputLabel>
          <OutlinedInput
            data-cy="providerSearchInput"
            id="search"
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton style={{ padding: 0 }}>
                  {isFetching ? (
                    <CircularProgress style={{ color: 'black' }} size={20} />
                  ) : (
                    <SearchIcon style={{ color: 'black' }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>

        <Box display="flex">
          <FormControl size="small" variant="outlined" className={classes.formControl}>
            <InputLabel id="location">Location</InputLabel>
            <Select
              label="Location"
              onChange={(event) => {
                const value = event.target.value as string;
                setSelectedMedspaLocation(value);
              }}
              id="medspaLocation"
              value={selectedMedspaLocation}
              fullWidth
            >
              <MenuItem value="all" key="practitioners-all">
                All Locations
              </MenuItem>
              <MenuItem value="none" key="practitioners-none">
                No Location
              </MenuItem>
              {sortBy(medspaLocations, (medspaLocation: IMedspaLocation) => medspaLocation.name).map(
                ({ id, name }: IMedspaLocation) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <FormControl size="small" variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="licenseSearch" color="secondary" className={classes.statusSelection}>
              Status
            </InputLabel>
            <SelectCustom
              data-cy="from"
              name="license"
              value={status}
              onChange={(e) => setStatus(e.target.value as string)}
              label="License"
            >
              <MenuItem>
                <em>All</em>
              </MenuItem>
              <MenuItem value="paid">Processed</MenuItem>
              <MenuItem value="unpaid">Pending</MenuItem>
            </SelectCustom>
          </FormControl>
          {!isMedspaAdmin && (
            <>
              <FormControl size="small" variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="year" color="secondary">
                  Year
                </InputLabel>
                <SelectCustom
                  id="year"
                  data-cy="from"
                  name="year"
                  value={year}
                  onChange={(e) => handleYearChange(e.target.value as string)}
                  label="Year"
                >
                  <MenuItem value={undefined}>All</MenuItem>
                  {availableYears.map((yearOption) => (
                    <MenuItem value={yearOption} key={yearOption}>
                      {yearOption}
                    </MenuItem>
                  ))}
                </SelectCustom>
              </FormControl>
              {!!year && (
                <FormControl size="small" variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="month" color="secondary">
                    Month
                  </InputLabel>
                  <SelectCustom
                    id="month"
                    data-cy="from"
                    name="month"
                    value={month}
                    onChange={(e) => setMonth(e.target.value as string)}
                    label="Month"
                    disabled={!year}
                  >
                    <MenuItem>All</MenuItem>
                    {longMonths.map((monthName, index) => (
                      <MenuItem value={index + 1} key={monthName}>
                        {monthName}
                      </MenuItem>
                    ))}
                  </SelectCustom>
                </FormControl>
              )}
            </>
          )}
        </Box>
      </div>
      <PortraitTable
        columns={TRANSACTION_COLUMNS}
        infiScroll
        isLoading={isLoading || isLoadingOldestRecord}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isError={isError}
        data={data}
        rowComponent={(row) => <FlexTransactionRow checkoutData={row} />}
        errorMessage="Error fetching transactions"
      />
    </div>
  );
};
