import { useQuery } from 'react-query';
import { MEDSPA_MERCHANT_ONBOARDING_REFRESH_URL } from 'src/constants/reactQuery.keys';
import MerchantOnboarding from 'src/services/MerchantOnboarding';

export const useMerchantOnboardingRefreshUrl = () =>
  useQuery({
    queryKey: [MEDSPA_MERCHANT_ONBOARDING_REFRESH_URL],
    queryFn: () => MerchantOnboarding.getRefreshUrl(),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      window.location.href = data.onboardingUrl;
    },
    onError: () => {
      window.location.href = '/';
    },
  });
