import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Checkbox,
  FormControl,
  Button,
  IconButton,
  OutlinedInput,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  InputAdornment,
} from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon, GetApp as GetAppIcon } from '@material-ui/icons';
import classnames from 'classnames';
import compile from '../../../utils/toastMessagesCompiler';
import DateComponent from '../../common/DateComponent';
import { ASSET_LABEL } from '../../../constants/general.constants';
import { CustomTextFiled, useStyles } from './purchaseOrder.styles';
import { dispatch } from '../../../rematch';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { TableCellHeader } from '../../common/TableStyles/TableCellHeader';
import { UNITS } from '../../../constants/medicalProfile.constants';

export const ProductsAddedTable = ({ isComplete, purchaseId, productsSelected }: ProductsAddedTableProps) => {
  const classes = useStyles();
  const { services = [] } = useSelector(({ common }: any) => common);
  const [errorValidate, setErrorValidate] = useState<boolean>(false);
  const DollarSign = (
    <InputAdornment position="start" className={isComplete ? classes.disabledDollarSign : classes.dollarSign}>
      $
    </InputAdornment>
  );

  const handleChangeIsSample = (
    id: number,
    event: React.ChangeEvent<{ name: string; value: string | number }> | any
  ) => {
    if (event.target.checked) {
      dispatch.newPurchaseOrder.setEditProduct({ id, name: 'cost', value: 0 });
    }
    dispatch.newPurchaseOrder.setEditProduct({ id, name: 'isSample', value: event.target.checked });
  };

  const handleProductEdit = (id: number, event: React.ChangeEvent<{ name: string; value: string | number }> | any) => {
    const { name, value } = event.target;
    const trimmedValue = typeof value === 'string' ? value.trim() : value;

    dispatch.newPurchaseOrder.setEditProduct({
      id,
      name,
      value: trimmedValue,
    });
  };

  const removeProductSelect = (id: number, isLocal: boolean) => {
    if (!isLocal) {
      dispatch.newPurchaseOrder.deleteOrderItem({ id });
    } else {
      dispatch.newPurchaseOrder.setRemoveProductSelected({ id });
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('purchase_order.item_delete'),
        },
      });
    }
  };

  const getLabel = (id: number, label: string): string => {
    const service = services.find((product: any) => product.id === id);

    if (service) {
      const { assetLabel, unitLabel } = service;
      return label === 'assetLabel' ? `${ASSET_LABEL[assetLabel]}s` : UNITS[unitLabel];
    }
    return '';
  };

  const downloadLabels = () => {
    window.open(`/administrator/pdf-generation/${purchaseId}`, '_blank', 'noopener,noreferrer');
  };

  const validateFormat = (value: string) => {
    const regex = /^[^/_]+$/;
    if (!value.match(regex)) {
      return setErrorValidate(true);
    }
    return setErrorValidate(false);
  };

  return (
    <Box padding={2} mb={4} height="100%">
      <div className={classes.orderBanner}>
        <span>Your Order {purchaseId}</span>
        {isComplete && (
          <Button
            data-cy="downloadLabelBtn"
            onClick={downloadLabels}
            variant="outlined"
            className={classnames(classes.button, classes.downloadButton)}
          >
            <GetAppIcon /> Download labels
          </Button>
        )}
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.rowHeight}>
              <TableCellHeader width="200">Product</TableCellHeader>
              <TableCellHeader width="100">Quantity</TableCellHeader>
              <TableCellHeader width="130" className="classes.textCenter">
                Lot
              </TableCellHeader>
              <TableCellHeader width="350">Expiration Date</TableCellHeader>
              <TableCellHeader width="100">Sample</TableCellHeader>
              <TableCellHeader width="100">Units/Each</TableCellHeader>
              <TableCellHeader width="120" className="classes.textCenter">
                Unit Cost
              </TableCellHeader>
              {!isComplete && <TableCellHeader width="80" />}
            </TableRow>
          </TableHead>
          <TableBody data-cy="tableBody">
            {productsSelected.map(
              ({ isLocal, id, name, quantity, lot, expireAt, units, cost, serviceId, isSample }: any) => (
                <TableRow data-cy="row" key={id} className={classes.rowHeight}>
                  <TableCellCustom data-cy="name">{name}</TableCellCustom>
                  <TableCellCustom>
                    <CustomTextFiled
                      data-cy="quantity"
                      name="quantity"
                      type="number"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleProductEdit(id, event)}
                      inputProps={{ min: 1 }}
                      value={quantity || 0}
                      disabled={isComplete}
                      label={getLabel(serviceId, 'assetLabel')}
                      variant="outlined"
                    />
                  </TableCellCustom>
                  <TableCellCustom>
                    <CustomTextFiled
                      data-cy="lot"
                      name="lot"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleProductEdit(id, event);
                        validateFormat(event.target.value);
                      }}
                      onKeyDown={(event: React.KeyboardEvent) => {
                        if (event.key === ' ') {
                          event.preventDefault();
                        }
                      }}
                      value={lot}
                      disabled={isComplete}
                      label={undefined}
                      variant="outlined"
                      error={errorValidate}
                    />
                  </TableCellCustom>
                  <TableCellCustom>
                    <DateComponent
                      isBack={false}
                      currentDate={expireAt && new Date(expireAt)}
                      getDateValue={(date) => {
                        handleProductEdit(id, { target: { name: 'expireAt', value: date } });
                      }}
                      selectProps={{
                        disabled: isComplete,
                      }}
                    />
                  </TableCellCustom>
                  <TableCellCustom>
                    <Checkbox
                      data-cy="sample"
                      color="default"
                      checked={isSample}
                      classes={{ checked: classes.checkbox }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeIsSample(id, event)}
                      name="isSample"
                      disabled={isComplete}
                    />
                  </TableCellCustom>
                  <TableCellCustom>
                    <CustomTextFiled
                      data-cy="units"
                      name="units"
                      type="number"
                      inputProps={{ min: 1 }}
                      disabled={isComplete}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleProductEdit(id, event)}
                      defaultValue={units || 1}
                      label={getLabel(serviceId, 'unitLabel')}
                      variant="outlined"
                    />
                  </TableCellCustom>
                  <TableCellCustom>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        data-cy="cost"
                        value={cost}
                        name="cost"
                        type="number"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleProductEdit(id, event)}
                        startAdornment={DollarSign}
                        inputProps={{
                          className: classnames(
                            isComplete ? classes.disabledInputProps : classes.inputProps,
                            classes.textLeft
                          ),
                          min: 0,
                        }}
                        disabled={isComplete || isSample}
                        labelWidth={undefined}
                      />
                    </FormControl>
                  </TableCellCustom>
                  {!isComplete && (
                    <TableCellCustom>
                      <IconButton onClick={() => removeProductSelect(id, isLocal)}>
                        <DeleteForeverIcon data-cy="delete" style={{ color: '#000000' }} />
                      </IconButton>
                    </TableCellCustom>
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

type ProductsAddedTableProps = {
  isComplete: boolean;
  purchaseId: any;
  productsSelected: object[];
};
