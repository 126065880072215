/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { Box, Button, Dialog, makeStyles } from '@material-ui/core';
import { InventoryOrder } from 'src/interfaces/IInventoryOrder';
import classNames from 'classnames';
import ISuppliers from 'src/interfaces/ISuppliers';
import { PAYMENT_STATUS_COMPLETED } from 'src/constants/inventory.constants';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import AddTrackingModal from './AddTrackingModal';
import AddConfirmationNumberModal from './AddConfirmationNumberModal';
import { OrderAddChargesModal } from './AddChargesModal';

type Props = {
  inventoryOrder: InventoryOrder;
  supplier: ISuppliers;
  approve: (supplierId: number) => Promise<void>;
};

export const ButtonsFooterSupplier = ({ inventoryOrder, supplier, approve }: Props) => {
  const classes = useStyles();
  const [addTrackingDialog, setAddTrackingDialog] = useState<boolean>(false);
  const [addConfirmationDialog, setAddConfirmationDialog] = useState<boolean>(false);
  const [addChargesDialog, setAddChargesDialog] = useState<boolean>(false);
  const [addDiscountsDialog, setAddDiscountsDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isLegacy = inventoryOrder.roleName === PORTRAIT_LEGACY || inventoryOrder.roleName === PORTRAIT_LEGACY_PLUS;

  const approved = inventoryOrder?.approvedSupplierIds?.indexOf(supplier.id) > -1;
  const allowAddOrderConfirmation =
    !inventoryOrder.cancelable && (isLegacy || inventoryOrder.paymentStatus === PAYMENT_STATUS_COMPLETED);

  const handleApprove = async () => {
    setIsLoading(true);
    await approve(supplier.id);
    setIsLoading(false);
  };

  return (
    <>
      <Box paddingY={0} className={classes.footerButtons}>
        <Button
          onClick={() => setAddChargesDialog(true)}
          variant="outlined"
          className={classNames(classes.actionButton, classes.chargesButton)}
          disabled={!inventoryOrder.cancelable || approved}
        >
          Add Additional Charge
        </Button>
        <Button
          onClick={() => setAddDiscountsDialog(true)}
          variant="outlined"
          className={classNames(classes.actionButton, classes.chargesButton)}
          disabled={!inventoryOrder.cancelable || approved}
        >
          Add Discount
        </Button>
        <Button
          onClick={() => setAddTrackingDialog(true)}
          variant="outlined"
          className={classNames(classes.actionButton, classes.chargesButton)}
          disabled={isLegacy && inventoryOrder.purchaseOrderTotal === 0}
        >
          Add Tracking #
        </Button>
        <Button
          onClick={() => setAddConfirmationDialog(true)}
          variant="outlined"
          className={classNames(classes.actionButton, classes.discountsButton)}
          disabled={!allowAddOrderConfirmation}
        >
          Add Order #
        </Button>
        <Box className={classes.buttonsContainer}>
          <Button
            data-cy="view"
            onClick={handleApprove}
            variant="contained"
            className={classes.actionButton}
            data-testid={`order approve ${inventoryOrder.id} ${supplier.id}`}
            disabled={isLoading || !inventoryOrder.cancelable || approved}
          >
            Approve
          </Button>
        </Box>
      </Box>
      <Dialog
        open={addTrackingDialog}
        maxWidth="lg"
        children={
          <AddTrackingModal id={inventoryOrder.id} supplier={supplier} dismiss={() => setAddTrackingDialog(false)} />
        }
        PaperProps={{ style: { width: '25%' } }}
      />
      <Dialog
        open={addConfirmationDialog}
        maxWidth="lg"
        children={
          <AddConfirmationNumberModal
            id={inventoryOrder.id}
            supplier={supplier}
            dismiss={() => setAddConfirmationDialog(false)}
          />
        }
        PaperProps={{ style: { width: '25%' } }}
      />
      <Dialog
        open={addChargesDialog}
        maxWidth="lg"
        children={
          <OrderAddChargesModal
            id={inventoryOrder.id}
            supplierId={supplier.id}
            dismiss={() => setAddChargesDialog(false)}
          />
        }
      />
      <Dialog
        open={addDiscountsDialog}
        maxWidth="lg"
        children={
          <OrderAddChargesModal
            id={inventoryOrder.id}
            supplierId={supplier.id}
            dismiss={() => setAddDiscountsDialog(false)}
            discountMode
          />
        }
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  footerButtons: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingRight: 25,
    marginTop: 15,
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
  },
  actionButton: {
    textTransform: 'none',
    fontSize: 14,
    minWidth: 132,
    height: 'auto',
    borderColor: '#ccc',
    color: '#000',
    backgroundColor: 'rgba(51,126,137,0)',
    margin: '0 auto 0 10px',
    '&:first-child': {
      margin: 0,
      padding: '8px 18px',
    },
    '&:last-child': {
      backgroundColor: 'rgba(29, 88, 77, 1)', // #1D584D
      border: '2px solid #1D584D',
      color: '#fff',
      margin: '0 0 0 auto',
      padding: '8px 18px',
      '&:disabled': {
        backgroundColor: 'rgba(29,88,77,0.65)',
        border: 'none',
      },
    },
    '&:last-child:hover': {
      backgroundColor: 'rgba(29, 88, 77, 0.45)',
    },
  },
  rejectButton: {
    color: '#E63E3E',
    border: '2px solid #E63E3E',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#E63E3E',
    },
  },
  chargesButton: {
    margin: 'auto 15px auto 0 !important',
  },
  discountsButton: {
    padding: '8px 18px',
  },
}));
