import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EHRButton } from 'src/components/ui/v1';
import { useMerchantOnboarding } from 'src/hooks/mutations/useMerchantOnboarding';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { RootState } from 'src/rematch';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { useMerchantAccount } from 'src/hooks/queries/medspaAdmins/useMerchantAccount';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import { CircularProgress } from '@material-ui/core';

interface Props {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus) => void;
}

const PaymentManagementSetup = ({ stepId, onStepUpdate }: Props) => {
  const { paymentProcessor, userGroupId } = useSelector(({ auth }: RootState) => auth);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { data: merchantAccount, isLoading, isError } = useMerchantAccount(userGroupId ?? 0);
  const { mutate: onboardMerchant } = useMerchantOnboarding();

  const stripeOnboarding = async () => {
    setIsRedirecting(true);
    onboardMerchant({ country: 'US' });
  };

  useEffect(() => {
    if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isError) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [isLoading, isError]);

  useEffect(() => {
    if (!merchantAccount) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
    if (
      merchantAccount?.['payments/merchantAccount'].onboardingStatus === 'completed' ||
      merchantAccount?.['payments/merchantAccount'].canProcessPayments
    ) {
      onStepUpdate(stepId, STEP_COMPLETED);
    }
  }, [merchantAccount]);

  const renderPaymentForm = () => {
    if (paymentProcessor === STRIPE_PAYMENT_PROCESSOR) {
      return (
        <EHRButton
          dataCy="stripe-onboarding"
          data-testid="stripe-onboarding"
          text={
            isRedirecting ? (
              <>
                Redirecting you to Stripe, please wait
                <CircularProgress size={20} style={{ marginLeft: 10, color: 'white' }} />
              </>
            ) : (
              'Become a Partner'
            )
          }
          color="primary"
          disabled={
            isLoading ||
            isRedirecting ||
            merchantAccount?.['payments/merchantAccount'].onboardingStatus === 'completed' ||
            merchantAccount?.['payments/merchantAccount'].canProcessPayments
          }
          onClick={() => stripeOnboarding()}
        />
      );
    }

    return null;
  };

  return isLoading ? <CircularProgress /> : <>{renderPaymentForm()}</>;
};

export default PaymentManagementSetup;
