import { useMutation } from 'react-query';
import Customers from '../../services/Customers';
import { dispatch } from '../../rematch';
import compile from '../../utils/toastMessagesCompiler';

export const useRemovePaymentMethod = (successCallback?: () => void) =>
  useMutation(
    ({ patientId, creditCardId }: { patientId: number; creditCardId: string }) =>
      Customers.removePaymentMethod(patientId, creditCardId),
    {
      onSuccess: () => successCallback?.(),
      onError: () => {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'removing',
              element: 'the credit card',
            }),
            type: 'error',
          },
        });
      },
    }
  );
