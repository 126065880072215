/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import classnames from 'classnames';
import { ReactComponent as MoneyIcon } from 'src/assets/images/money.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TableCellHeader } from 'src/components/common/TableStyles/TableCellHeader';
import SignatureCanvas from 'react-signature-canvas';
import { SQUARE_STATUS } from 'src/constants/square.constants';
import { STRIPE_STATUS } from 'src/constants/stripe.constants';
import { useStyles } from './ServiceVisitCheckoutSummaryModal.styles';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { formatNumber } from '../../utils/checkout.utils';
import { CASH_CHARGE_TYPE, TENDER_TYPE_TO_SHOW } from './RemainingToPay';
import { FEE_RELATED_CHARGE_TYPES, TENDER_TYPE_NAMES } from '../../constants/checkout.constants';
import { ISquareCard } from '../../interfaces/ISquareCard.interfaces';
import CreditCardIcon from '../../components/PatientProfile/CreditCards/CreditCardIcon';
import { ILineItem } from '../../interfaces/IServiceVisits';
import { dispatch } from '../../rematch';
import compile from '../../utils/toastMessagesCompiler';
import { Button } from '../../components/common/Button';

interface ServiceVisitCheckoutSummaryModalProps {
  open: boolean;
  onClose: () => void;
  subTotal: number;
  total: number;
  discounts?: number;
  accountCreditUsed?: number;
  checkoutTenders?: any;
  referralValue?: number;
  serviceCreditsValue?: number;
  cashAmount?: number | undefined;
  selectedCard?: ISquareCard;
  handlePayment: (args: any) => void;
  transactionStatus: string;
  lineItems: ILineItem[];
  chargeType: string;
  checkoutChargeType?: string;
  checkoutSignatureUrl: string;
  updateCheckout: any;
  payWithStripe?: boolean;
  paymentTransactionStatus?: string;
}

export const ServiceVisitCheckoutSummaryModal = ({
  open,
  onClose,
  subTotal,
  total,
  discounts,
  accountCreditUsed,
  checkoutTenders = [],
  referralValue,
  serviceCreditsValue,
  cashAmount,
  selectedCard,
  handlePayment,
  transactionStatus,
  paymentTransactionStatus,
  lineItems,
  chargeType,
  checkoutChargeType,
  checkoutSignatureUrl,
  updateCheckout,
  payWithStripe,
}: ServiceVisitCheckoutSummaryModalProps) => {
  let signCanvas: any;
  const classes = useStyles();
  const [selectedTipPercentage, setSelectedTipPercentage] = useState<number | null>();
  const [processingTransaction, setProcessingTransaction] = useState<boolean>(false);
  const tipOptions = [5, 10, 15, 20];
  const tipAmount = (total * (selectedTipPercentage || 0)) / 100;
  const [isSigned, setIsSigned] = useState(false);
  const [signature, setSignature] = useState<string>(checkoutSignatureUrl);

  useEffect(() => {
    if (chargeType === CASH_CHARGE_TYPE) {
      setSelectedTipPercentage(undefined);
    }
  }, [chargeType]);

  useEffect(() => {
    if (payWithStripe && paymentTransactionStatus === STRIPE_STATUS.FAILED) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'charging',
            element: 'customer',
          }),
          type: 'error',
          duration: 5000,
        },
      });
      setProcessingTransaction(false);
      onClose();
    }
  }, [paymentTransactionStatus]);

  useEffect(() => {
    if (transactionStatus === SQUARE_STATUS.OK || paymentTransactionStatus === STRIPE_STATUS.OK) {
      setProcessingTransaction(false);
      onClose();
    }
  }, [transactionStatus, paymentTransactionStatus]);

  const clearSignature = () => {
    setIsSigned(false);
    setSignature('');
    if (signCanvas) {
      signCanvas.clear();
    }
  };

  const onBeginStroke = () => {
    setIsSigned(true);
  };

  const confirmPayment = async () => {
    setProcessingTransaction(true);
    if (isSigned) {
      const signatureImage = signCanvas.getTrimmedCanvas().toDataURL('image/png');
      await updateCheckout({ checkoutSignature: signatureImage });
    }

    await handlePayment({
      cardId: selectedCard?.id || '',
      tipAmount,
      cashAmount,
      total,
      payWithStripe,
      onError: () => {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'charging',
              element: 'customer',
            }),
            type: 'error',
            duration: 2500,
          },
        });
        setProcessingTransaction(false);
      },
    });
  };

  return (
    <Modal className={classes.modal} open={open}>
      <Box className={classes.modalContent}>
        <NavigationBar hideHomeButton title="Summary" onBackButtonClick={onClose} />
        <Box className={classes.summaryContainer}>
          <Box className={classes.summary}>
            <h2 className={classes.summaryBold}>Summary</h2>
            <Box>
              <Table data-cy="table">
                <TableHead>
                  <TableRow>
                    <TableCellHeader className={classes.summaryLineItemHeader}>Product</TableCellHeader>
                    <TableCellHeader
                      className={classnames(classes.summaryLineItemHeader, classes.summaryLineItemRight)}
                    >
                      Qty.
                    </TableCellHeader>
                    <TableCellHeader
                      className={classnames(classes.summaryLineItemHeader, classes.summaryLineItemRight)}
                    >
                      Unit Price
                    </TableCellHeader>
                    <TableCellHeader
                      className={classnames(classes.summaryLineItemHeader, classes.summaryLineItemRight)}
                    >
                      Total
                    </TableCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineItems.map((lineItem) => (
                    <TableRow>
                      <TableCell className={classes.summaryLineItemBody}>{lineItem.serviceName}</TableCell>
                      <TableCell className={classnames(classes.summaryLineItemBody, classes.summaryLineItemRight)}>
                        {lineItem.quantity}
                      </TableCell>
                      <TableCell className={classnames(classes.summaryLineItemBody, classes.summaryLineItemRight)}>
                        ${formatNumber(+(lineItem.unitPriceOverride || lineItem.unitPrice))}
                      </TableCell>
                      <TableCell className={classnames(classes.summaryLineItemBody, classes.summaryLineItemRight)}>
                        ${formatNumber(lineItem.totalWithoutDiscount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <hr className={classes.summaryLineBreak} />
            <Box className={classes.summaryLineItem}>
              <div>Subtotal</div>
              <div>${formatNumber(subTotal)}</div>
            </Box>
            {!!discounts && (
              <Box className={classes.summaryLineItem}>
                <div>Discounts</div>
                <div>- ${formatNumber(discounts)}</div>
              </Box>
            )}
            {!!serviceCreditsValue && (
              <Box className={classes.summaryLineItem}>
                <div>Service Credits</div>
                <div>- ${formatNumber(serviceCreditsValue)}</div>
              </Box>
            )}
            {checkoutTenders
              .filter(
                ({ tenderType, value }: { tenderType: string; value: number }) =>
                  value > 0 && TENDER_TYPE_TO_SHOW.includes(tenderType)
              )
              .map(({ tenderType, value }: { tenderType: string; value: number }) => (
                <Box className={classes.summaryLineItem}>
                  <div
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: TENDER_TYPE_NAMES[tenderType] }}
                  />
                  <div>- ${formatNumber(value)}</div>
                </Box>
              ))}
            {!!referralValue && (
              <Box className={classes.summaryLineItem}>
                <div>Referral Credit</div>
                <div>- ${formatNumber(referralValue)}</div>
              </Box>
            )}
            {!!accountCreditUsed && (
              <Box className={classes.summaryLineItem}>
                <div>Account Credit</div>
                <div>- ${formatNumber(accountCreditUsed)}</div>
              </Box>
            )}
            <Box className={classes.summaryLineItem}>
              <div className={classes.summaryBold}>Total</div>
              <div className={classes.summaryBold}>${formatNumber(total)}</div>
            </Box>
            <hr className={classes.summaryLineBreak} />
            {!cashAmount && !FEE_RELATED_CHARGE_TYPES.includes(checkoutChargeType ?? '') && (
              <>
                <Box className={classes.tipsContainer}>
                  <p>
                    <div className={classes.summaryLineBreak}>
                      <span className={classes.summaryBold}>Tip</span>{' '}
                      <span className={classes.optional}>(Optional)</span>
                    </div>
                  </p>
                  <Box className={classes.tipOptionsContainer}>
                    <button
                      type="button"
                      disabled={processingTransaction}
                      className={selectedTipPercentage === null ? classes.selectedTipOption : classes.tipOption}
                      onClick={() => setSelectedTipPercentage(null)}
                    >
                      No Tips
                    </button>
                    {tipOptions.map((tipOption) => (
                      <button
                        type="button"
                        disabled={processingTransaction}
                        className={selectedTipPercentage === tipOption ? classes.selectedTipOption : classes.tipOption}
                        onClick={() => setSelectedTipPercentage(tipOption)}
                      >
                        {tipOption}%
                      </button>
                    ))}
                  </Box>
                  {selectedTipPercentage && !cashAmount && (
                    <Box className={classes.summaryLineItem}>
                      <div>Tips {selectedTipPercentage}%</div>
                      <div>${formatNumber(tipAmount)}</div>
                    </Box>
                  )}
                </Box>
                <hr className={classes.summaryLineBreak} />
              </>
            )}
            {!FEE_RELATED_CHARGE_TYPES.includes(checkoutChargeType ?? '') && (
              <Box className={classes.signCheckoutContainer}>
                <h2 className={classes.summaryBold}>Sign</h2>
                {signature ? (
                  <Box className={classes.signatureImageContainer}>
                    <img src={checkoutSignatureUrl} alt="signature" className={classes.signatureImage} />
                  </Box>
                ) : (
                  <Box className={classes.signatureContainer}>
                    <SignatureCanvas
                      canvasProps={{ className: classes.signatureBox }}
                      ref={(ref) => {
                        signCanvas = ref;
                      }}
                      onBegin={onBeginStroke}
                      clearOnResize={false}
                    />
                  </Box>
                )}
                <Box className={classes.clearSignatureContainer}>
                  <Button
                    className={classes.clearSignatureButton}
                    disabled={processingTransaction}
                    title="Clear"
                    onClick={clearSignature}
                  />
                </Box>
              </Box>
            )}
            {!!selectedCard && chargeType !== 'cash' && (
              <Box className={classnames(classes.summaryLineItem, classes.payWithContainer)}>
                <div>Pay with</div>
                <div className={classes.cardName}>
                  Card ending in {selectedCard.last4}
                  <span className={classes.cardIcon}>
                    <CreditCardIcon cardBrand={selectedCard.cardBrand} />
                  </span>
                </div>
              </Box>
            )}
            {chargeType === 'cash' && !!cashAmount && (
              <Box className={classes.payWithCashContainer}>
                <Box className={classnames(classes.summaryLineItem, classes.mt0)}>
                  <div>Pay with</div>
                  <div className={classes.cardName}>
                    <MoneyIcon />
                    <span className={classes.ml05}>Cash</span>
                  </div>
                </Box>
                <hr className={classes.payWithLineBreak} />
                <Box className={classes.summaryLineItem}>
                  <div>Cash Received</div>
                  <div>${formatNumber(cashAmount)}</div>
                </Box>
                {cashAmount > total && (
                  <Box className={classes.summaryLineItem}>
                    <div>Cashback</div>
                    <div>${formatNumber(cashAmount - total)}</div>
                  </Box>
                )}
              </Box>
            )}
            <button
              type="button"
              className={classes.payButton}
              disabled={
                processingTransaction ||
                (!isSigned && !signature && !FEE_RELATED_CHARGE_TYPES.includes(checkoutChargeType ?? ''))
              }
              onClick={confirmPayment}
            >
              {processingTransaction ? (
                <CircularProgress style={{ color: 'white' }} size={25} />
              ) : (
                <>PAY: ${formatNumber(total + tipAmount)}</>
              )}
            </button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
