import React, { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  FormHelperText,
  Link,
  makeStyles,
  MenuItem,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import {
  EHRAutocompleteSelect,
  EHRButton,
  EHRCRUDModal,
  EHRDatetimeStartEndRange,
  EHRDatetimeStartEndRangeSelector,
  EHRMultipleSelect,
  EHRSelect,
  EHRTextField,
  EHRTypography,
  IAutocompleteSelectOption,
  EHRModal,
  validateInputConfirmation,
  MissingInput,
  EHRCheckboxWithText,
} from 'src/components/ui/v1';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { useCustomerSelector } from 'src/hooks/queries/customers/customers';
import { IAppointmentType } from 'src/interfaces/acuity/acuity.interface';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { DEVICE_TYPE, useDeviceType } from 'src/utils/device';
import {
  useCheckBookingTime,
  useCreateMedspaAppointment,
  useRescheduleMedspaAppointment,
} from 'src/hooks/medspaCalendar/useAppointments';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import moment from 'moment';
import { useGetMedspaAddons } from 'src/hooks/medspaCalendar/useAddons';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { IAddon } from 'src/services/medspaAdmins/MedspaCalendar';
import { useStates } from 'src/hooks/queries/useStates';
import { useServiceGroups, useServices } from 'src/hooks/queries/services/useServices';
import IServices from 'src/interfaces/IServices';
import { Skeleton } from '@material-ui/lab';
import { SNACKBAR_ERROR, MEDSPA_ADMIN_ROLE } from 'src/constants/general.constants';
import { showSnackbar } from 'src/utils/global';
import { ExtendedAppointmentModel } from './grouping';
import { VALID_EMAIL, VALID_PHONE, VALID_PHONE_REGEX } from '../../../../constants/schemaForm.constants';
import PhoneNumberCustom from '../../../common/InputPhoneNumber';
import {
  MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG,
  MEDSPA_ADMIN_SERVICES_MANAGEMENT,
} from '../../../../routes/medspaAdminRoutes';
import IState from '../../../../interfaces/IState';

const existingPatientRequiredValidation = (message: string) => (existingPatient: boolean) => {
  if (existingPatient) {
    return yup.string().nullable();
  }
  return yup.string().required(message);
};

const appointmentSchema = yup.object().shape({
  appointmentType: yup.number().nullable().required('You must select an appointment type'),
  start: yup.date().required('You must pick a start time'),
  end: yup.date().nullable().required('You must select an appointment type'),
  existingPatient: yup.boolean(),
  customerEmail: yup.string().when(['existingPatient'], (existingPatient: boolean) => {
    if (existingPatient) {
      return yup.string().required('You must select a patient');
    }
    return yup.string().required('Email is required').email(VALID_EMAIL);
  }),
  customerFirstName: yup
    .string()
    .when(['existingPatient'], existingPatientRequiredValidation('First name is required')),
  customerLastName: yup.string().when(['existingPatient'], existingPatientRequiredValidation('Last name is required')),
  customerPhone: yup.string().when(['existingPatient'], (existingPatient: boolean) => {
    if (existingPatient) {
      return yup.string().nullable();
    }
    return yup.string().required('Phone is required').matches(VALID_PHONE_REGEX, VALID_PHONE);
  }),
  customerAddressCity: yup.string().when(['existingPatient'], existingPatientRequiredValidation('City is required')),
  customerAddressState: yup.string().when(['existingPatient'], existingPatientRequiredValidation('State is required')),
  customerAddressAddressLine: yup
    .string()
    .when(['existingPatient'], existingPatientRequiredValidation('Address line is required')),
  customerAddressZipCode: yup
    .string()
    .when(['existingPatient'], existingPatientRequiredValidation('ZIP code is required')),
  practitionerId: yup.number().required('You must select a practitioner'),
  addOnDuration: yup.number().nullable(),
  addOnIds: yup.array(),
  extraTime: yup.string().nullable(),
  notes: yup.string().nullable(),
  serviceGroups: yup.array(),
  services: yup.array(),
});

const useStyle = makeStyles((theme) => ({
  appointmentCRUDModal: {},
  phoneNumberInput: {
    width: '100%',
    '& > div > input': {
      padding: '0.75rem 1rem',
    },
  },
  conflictModal: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
      borderTop: 'none',
      padding: theme.spacing(2, 3, 3),
    },
  },
  conflictModalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}));

interface AppointmentCRUDModalProps {
  appointmentData: ExtendedAppointmentModel;
  open: boolean;
  onClose: () => void;
  isNewAppointment: boolean;
  selectedMedspaLocation: IMedspaLocation;
  selectedProvider: IPractitioner;
  providersMap: { [key: number]: IPractitioner };
  appointmentTypes: IAppointmentType[];
}

const AppointmentCRUDModal = (props: AppointmentCRUDModalProps) => {
  const { userGroupId, roleName } = useSelector(({ auth }: RootState) => auth);
  const device = useDeviceType();
  const DateTimePickerSelector =
    device === DEVICE_TYPE.DESKTOP ? EHRDatetimeStartEndRangeSelector : EHRDatetimeStartEndRange;
  const classes = useStyle();

  const {
    appointmentData,
    open,
    onClose,
    isNewAppointment,
    selectedMedspaLocation,
    selectedProvider,
    providersMap,
    appointmentTypes: allAppointmentTypes,
  } = props;
  // DateTimePickerSelector confirmation input:
  const [missingConfirmationInput, setMissingConfirmationInput] = useState<MissingInput>('');

  const appointmentTypes = React.useMemo(() => {
    const locationId = selectedMedspaLocation?.practitioners.find(
      (practitioner) => practitioner.id === selectedProvider.id
    )?.locationId;
    return locationId
      ? allAppointmentTypes.filter(({ locations }: IAppointmentType) => locations.includes(locationId))
      : allAppointmentTypes;
  }, [allAppointmentTypes, selectedProvider.id, selectedMedspaLocation]);
  const [existingPatient, setExistingPatient] = useState<boolean>(true);
  const [currentProvider, setCurrentProvider] = useState<IPractitioner>(selectedProvider);
  const { emailOptions = [], isFetching: isFetchingCustomers } = useCustomerSelector({
    search: '',
    practitionerId: appointmentData.practitionerId || '',
  });
  const { mutate: mutateCreateMedspaAppointment, isLoading: isCreatingAppointment } = useCreateMedspaAppointment(() =>
    onClose()
  );

  const { mutateAsync: mutateCheckBookingTime, isLoading: isCheckingBookingTime } = useCheckBookingTime();

  const { mutate: mutateRescheduleMedspaAppointment, isLoading: isReschedulingAppointment } =
    useRescheduleMedspaAppointment(appointmentData.id, () => onClose());

  const { handleSubmit, errors, setValue, getValues, watch, setError, clearErrors, control } = useForm({
    resolver: yupResolver(appointmentSchema),
    defaultValues: {
      appointmentType: null,
      start: appointmentData.startDate,
      end: null,
      practitionerId: currentProvider?.id,
      existingPatient: true,
      addOnDuration: appointmentData.addOnDuration,
      extraTime: null,
      notes: '',
      customerEmail: '',
      customerFirstName: '',
      customerLastName: '',
      customerPhone: '',
      customerAddressCity: '',
      customerAddressState: '',
      customerAddressAddressLine: '',
      customerAddressZipCode: '',
      confirm: false,
      addOnIds: [],
      serviceGroups: [],
      services: [],
    },
  });
  const watchServiceGroups = watch('serviceGroups');
  const watchServices = watch('services');
  const appointmentTypeId = watch('appointmentType');
  const customerEmail = watch('customerEmail');
  const notes = watch('notes');
  const addOnIds = watch('addOnIds');
  const watchConfirm = watch('confirm');
  const { data: serviceGroups, isFetching: isFetchingServiceGroups } = useServiceGroups();
  const { data: services, isFetching: isFetchingServices } = useServices();
  const servicesOptions = React.useMemo(
    () =>
      services
        .filter(({ serviceGroupId }: IServices) => watchServiceGroups?.includes(serviceGroupId))
        .map(({ name, id }: IServices) => ({ name, value: id })),
    [watchServiceGroups]
  );
  const isAdmin = roleName === MEDSPA_ADMIN_ROLE;

  const { data: addonsData, isLoading: isLoadingAddons } = useGetMedspaAddons(userGroupId as number);
  const { data: states = [], isFetching: isFetchingStates } = useStates();

  const { addons } = addonsData || { addons: [] };

  const statesCodeToNameMap = useMemo(() => {
    if (states?.length) {
      return states.reduce(
        (obj: Record<string, string>, stateObj: IState) => Object.assign(obj, { [stateObj.code]: stateObj.name }),
        {}
      );
    }
    return {};
  }, [states]);

  const [conflictsModal, setConflictsModal] = useState<string | null>(null);
  const [conflictDetails, setConflictDetails] = useState<{ time: string; providerName: string } | null>(null);

  const filteredProvidersArray = React.useMemo(() => {
    if (!selectedMedspaLocation?.practitioners) {
      return [];
    }

    return Object.values(providersMap).filter((practitioner) =>
      selectedMedspaLocation.practitioners.some((locationPractitioner) => locationPractitioner.id === practitioner.id)
    );
  }, [selectedMedspaLocation, providersMap]);

  const handleAppointmentAction = (params: any, action: 'create' | 'reschedule', override?: boolean) => {
    const mutateFunction = action === 'create' ? mutateCreateMedspaAppointment : mutateRescheduleMedspaAppointment;

    const updatedParams = {
      ...params,
      appointment: {
        ...params.appointment,
        override,
      },
    };
    delete updatedParams.appointment.time;
    delete updatedParams.appointment.providerName;

    mutateFunction(updatedParams, {
      onError: (error: any) => {
        let errorMessage = `Failed to ${action} appointment`;
        if (error.response && error.response.data) {
          errorMessage =
            error.response.data.message || error.response.data.error || error.response.data.errors || errorMessage;
        } else if (error.message) {
          errorMessage = error.message;
        }

        showSnackbar(errorMessage, SNACKBAR_ERROR);
      },
    });
  };

  const handleCloseConflictModal = () => {
    setConflictsModal(null);
    setConflictDetails(null);
  };

  useEffect(() => {
    setValue('practitionerId', currentProvider?.id);
  }, [currentProvider]);
  useEffect(() => {
    setValue('existingPatient', existingPatient);
  }, [existingPatient]);
  useEffect(() => {
    setValue('start', appointmentData.startDate);
  }, [appointmentData.startDate]);
  useEffect(() => {
    setValue('end', appointmentData.endDate);
  }, [appointmentData.endDate]);
  useEffect(() => {
    setValue('appointmentType', appointmentData.appointmentTypeId);
  }, [appointmentData.appointmentTypeId]);
  useEffect(() => {
    let newAddonId: number | undefined;
    if (appointmentData.addOns?.length) {
      newAddonId = appointmentData.addOns[0].id;
    }
    if (
      !isNewAppointment &&
      !isLoadingAddons &&
      !!appointmentTypeId &&
      addOnIds.length === 0 &&
      !!newAddonId &&
      !addOnIds.includes(newAddonId)
    ) {
      onSelectAddon(newAddonId);
    }
  }, [appointmentData.addOnDuration, addons, appointmentTypeId, isNewAppointment]);
  useEffect(() => {
    setValue('notes', appointmentData.notes);
  }, [appointmentData.notes]);
  useEffect(() => {
    setValue('confirm', !!appointmentData.confirmedAt);
  }, [appointmentData.confirmedAt]);
  useEffect(() => {
    setValue('customerEmail', appointmentData.patientEmail);
  }, [appointmentData.patientEmail]);
  useEffect(() => {
    if (appointmentData.serviceGroups && appointmentData.serviceGroups.length > 0) {
      const serviceGroupsSelected = appointmentData.serviceGroups.map((serviceGroup) => serviceGroup.id);
      setValue('serviceGroups', serviceGroupsSelected);
    }
  }, [appointmentData.serviceGroups, setValue]);
  useEffect(() => {
    if (appointmentData.services && appointmentData.services.length > 0) {
      const servicesSelected = appointmentData.services.map((service) => service.id);
      setValue('services', servicesSelected);
    }
  }, [appointmentData.services, setValue]);

  const onSelectAddon = (selectedaddOnIds: string | number) => {
    const retrievedAddon = addons.find((addon) => addon.id === selectedaddOnIds);
    const { start } = getValues();
    const newHourEnd = moment(start).clone();
    if (retrievedAddon) {
      setValue('addOnIds', [selectedaddOnIds]);
      setValue('addOnDuration', retrievedAddon.duration);
      newHourEnd.add(retrievedAddon.duration, 'minutes');
    } else {
      setValue('addOnDuration', null);
      setValue('addOnIds', []);
    }
    const { appointmentType } = getValues();
    const retrievedAppType = appointmentTypes.find((appTyp) => appTyp.id === appointmentType);
    if (retrievedAppType) {
      newHourEnd.add(retrievedAppType.duration, 'minutes');
    }
    setValue('end', newHourEnd.toDate());
  };

  const onSubmit = async (
    dataToCreate: typeof appointmentSchema['fields'] & FieldValues,
    _: any,
    override: boolean = false
  ) => {
    const isDateRangeValid = validateInputConfirmation(missingConfirmationInput, setError, clearErrors);

    if (!dataToCreate || !isDateRangeValid) {
      return;
    }
    const params = {
      appointment: {
        practitionerId: dataToCreate.practitionerId,
        medspaLocationId: selectedMedspaLocation?.id,
        ...(existingPatient && {
          customer: {
            email: dataToCreate.customerEmail,
          },
        }),
        ...(!existingPatient && {
          customer: {
            firstName: dataToCreate.customerFirstName,
            lastName: dataToCreate.customerLastName,
            email: dataToCreate.customerEmail,
            phone: dataToCreate.customerPhone,
            addressLine1: dataToCreate.customerAddressAddressLine,
            city: dataToCreate.customerAddressCity,
            // @ts-ignore
            state: statesCodeToNameMap[dataToCreate.customerAddressState] || '',
            zipCode: dataToCreate.customerAddressZipCode,
          },
        }),
        datetime: moment(dataToCreate.start).format('YYYY-MM-DDTHH:mm'),
        notes: dataToCreate.notes,
        appointmentTypeId: dataToCreate.appointmentType,
        addOnDuration: dataToCreate.addOnDuration,
        addOnIds: dataToCreate.addOnIds,
        serviceGroups: dataToCreate.serviceGroups,
        services: dataToCreate.services,
        confirm: dataToCreate.confirm,
        time: moment(dataToCreate.start).format('HH:mm'),
        providerName: `${currentProvider?.firstName} ${currentProvider?.lastName}`,
      },
    };

    if (!override) {
      const check = await mutateCheckBookingTime({
        ...params,
        appointment: { ...params.appointment, id: appointmentData.id },
      });

      if (!check.success) {
        setConflictDetails({
          time: params.appointment.time,
          providerName: params.appointment.providerName,
        });
        setConflictsModal(check.warnings.join(', '));
        return;
      }
    }

    if (isNewAppointment) {
      handleAppointmentAction(params, 'create', override);
    } else {
      handleAppointmentAction(params, 'reschedule', override);
    }
  };

  const { start: formStart } = getValues();
  const startTime = formStart || appointmentData.startDate;
  const endTime = watch('end');

  const isLoading = isLoadingAddons || isFetchingStates;
  const isMutating =
    isCreatingAppointment || isReschedulingAppointment || isCheckingBookingTime || !appointmentTypes.length;

  const selectedPatient = emailOptions.find((emailOption) => emailOption.value === customerEmail) || {
    value: appointmentData.patientEmail,
    name: appointmentData.patientName,
  };

  return (
    <>
      <EHRCRUDModal
        dataCy="modal-edit-appointment"
        open={open}
        onClose={onClose}
        className={classes.appointmentCRUDModal}
      >
        <EHRCRUDModal.Title
          dataCy="modal-edit-appointment-modal-title"
          title={`${isNewAppointment ? 'New' : 'Reschedule'} Appointment`}
          handleClose={onClose}
        />
        <EHRCRUDModal.Body dataCy="modal-edit-appointment-modal-body">
          <Box width="100%">
            <Box>
              <EHRTextField
                dataCy="input-medspaLocation"
                label="Location"
                value={[
                  selectedMedspaLocation?.name,
                  selectedMedspaLocation?.address,
                  selectedMedspaLocation?.city,
                  selectedMedspaLocation?.state,
                  selectedMedspaLocation?.zipCode,
                ].join(', ')}
                helperText=" "
                readOnly
              />
            </Box>

            <Box marginBottom="1rem">
              {isAdmin ? (
                <>
                  <EHRSelect
                    label="Provider"
                    dataCy="select-provider"
                    fullWidth
                    value={currentProvider?.id || ''}
                    disabled={isMutating || isFetchingCustomers}
                    error={!!errors?.practitionerId}
                    onChange={(selectedPractitionerId: number | string) => {
                      const selectedPractitioner = filteredProvidersArray.find(
                        (provider) => provider.id === Number(selectedPractitionerId)
                      );

                      if (selectedPractitioner) {
                        setCurrentProvider(selectedPractitioner);
                        setValue('practitionerId', Number(selectedPractitionerId));
                      }
                    }}
                  >
                    {filteredProvidersArray.map((practitioner) => (
                      <MenuItem
                        key={practitioner.id}
                        value={practitioner.id}
                        style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                      >
                        <Box display="flex" alignItems="center" width="100%" style={{ gap: '1rem' }}>
                          <Avatar style={{ width: '30px', height: '30px' }} src={practitioner.profileImageUrl} />
                          <span>
                            {practitioner.firstName} {practitioner.lastName}
                          </span>
                        </Box>
                      </MenuItem>
                    ))}
                  </EHRSelect>
                </>
              ) : (
                <Box display="flex" alignItems="center" gridGap="0.5rem">
                  <Avatar src={currentProvider?.profileImageUrl} />
                  <span>
                    {currentProvider?.firstName} {currentProvider?.lastName}
                  </span>
                </Box>
              )}
            </Box>

            <Box>
              {appointmentTypes.length > 0 ? (
                <EHRSelect
                  label="Appointment Type"
                  dataCy="select-appointmentType"
                  onChange={(selectedAppointmentTypeId: number | string) => {
                    const retrievedAppType = appointmentTypes.find((appTyp) => appTyp.id === selectedAppointmentTypeId);
                    const { start } = getValues();
                    const newHourEnd = moment(start).clone();
                    if (retrievedAppType) {
                      newHourEnd.add(retrievedAppType.duration, 'minutes');
                      setValue('appointmentType', selectedAppointmentTypeId);
                    } else {
                      setValue('appointmentType', null);
                    }
                    const { addOnDuration } = getValues();
                    if (addOnDuration) {
                      newHourEnd.add(addOnDuration, 'minutes');
                    }
                    setValue('end', newHourEnd.toDate());
                  }}
                  id="appointmentType"
                  value={appointmentTypeId}
                  fullWidth
                  displayEmpty
                  disabled={isMutating || isFetchingCustomers}
                  error={!!errors?.appointmentType}
                  helperText={errors?.appointmentType?.message || ' '}
                >
                  {appointmentTypes.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </EHRSelect>
              ) : (
                <Box marginBottom="1rem">
                  <Box marginBottom="0.25rem">
                    <EHRTypography dataCy="label-service-group" variant="label" style={{ color: 'red' }}>
                      Appointment Type*
                    </EHRTypography>
                  </Box>
                  <Box>
                    You don&apos;t have any appointment types for this location. Please click{' '}
                    <Link href={MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG}>here</Link> to add appointment types.
                  </Box>
                </Box>
              )}
            </Box>
            {!serviceGroups.length || !services.length ? (
              <Box marginBottom="1rem">
                <Box marginBottom="0.25rem">
                  <EHRTypography dataCy="label-service-group" variant="label">
                    Service Groups (optional)
                  </EHRTypography>
                </Box>
                {!isFetchingServiceGroups && !isFetchingServices ? (
                  <Box>
                    No Services have been created click <Link href={MEDSPA_ADMIN_SERVICES_MANAGEMENT}>here</Link> to add
                    Services to the MedSpa
                  </Box>
                ) : (
                  <Skeleton height={48} />
                )}
              </Box>
            ) : (
              <>
                <Box marginBottom="1rem">
                  <Box marginBottom="0.25rem">
                    <EHRTypography dataCy="label-service-group" variant="label">
                      Service Groups (optional)
                    </EHRTypography>
                  </Box>
                  <EHRMultipleSelect
                    dataCy="select-service-groups"
                    disabled={isFetchingServiceGroups || !isNewAppointment}
                    name="serviceGroups"
                    value={watchServiceGroups || []}
                    onChange={(newVal: any) => setValue('serviceGroups', newVal)}
                    error={!!errors.serviceGroups}
                    helperText={errors.serviceGroups?.message}
                    options={serviceGroups.map(({ name, id }) => ({ name, value: id }))}
                  />
                </Box>
                <Box marginBottom="1rem">
                  <Box marginBottom="0.25rem">
                    <EHRTypography dataCy="label-services" variant="label">
                      Add Services (optional)
                    </EHRTypography>
                  </Box>
                  <EHRMultipleSelect
                    dataCy="select-services"
                    disabled={isFetchingServices || !isNewAppointment}
                    name="services"
                    value={watchServices || []}
                    onChange={(newVal: any) => setValue('services', newVal)}
                    error={!!errors.services}
                    helperText={errors.services?.message}
                    options={servicesOptions}
                  />
                </Box>
              </>
            )}
            {addons.length > 0 && (
              <Box marginBottom="1rem">
                <EHRSelect
                  label="Add Extra Time (optional)"
                  dataCy="select-addServices"
                  value={addOnIds}
                  onChange={(selectedaddOnIds: number | string) => onSelectAddon(selectedaddOnIds)}
                  id="addServices"
                  fullWidth
                  displayEmpty
                  helperText=" "
                  tooltip="Additional time for this appointment"
                  disabled={isMutating || isLoadingAddons}
                >
                  {[{ id: '', name: 'No extra time' }]
                    // @ts-ignore
                    .concat(addons)
                    // @ts-ignore
                    .map((addon: IAddon) => (
                      <MenuItem key={addon.id} value={addon.id}>
                        {addon.name}
                      </MenuItem>
                    ))}
                </EHRSelect>
              </Box>
            )}

            <Box marginBottom="1rem">
              <DateTimePickerSelector
                dataCy="input-datetimeStartEndRange"
                label="Date & Time"
                dateRange={{
                  start: startTime as Date,
                  end: endTime,
                }}
                startTimeSelectorError={!!errors.start}
                startTimeSelectorHelperText={errors.start?.message || ''}
                endTimeSelectorDisabled
                endTimeSelectorError={!!errors.end}
                endTimeSelectorHelperText={errors.end?.message || ''}
                onChange={(newDateTimeRange) => {
                  const newHourStart = moment(newDateTimeRange.start).clone();
                  const newHourEnd = moment(newDateTimeRange.start).clone();
                  const { appointmentType, addOnDuration } = getValues();
                  const retrievedAppType = appointmentTypes.find((appTyp) => appTyp.id === appointmentType);
                  if (retrievedAppType) {
                    newHourEnd.add(retrievedAppType.duration, 'minutes');
                  }
                  if (addOnDuration) {
                    newHourEnd.add(addOnDuration, 'minutes');
                  }
                  setValue('start', newHourStart.toDate());
                  setValue('end', newHourEnd.toDate());
                }}
                handleMissingConfirmation={(missingInput) => {
                  if (missingInput !== missingConfirmationInput) {
                    setMissingConfirmationInput(missingInput);
                  }
                }}
              />
            </Box>
            {!(appointmentData.id && appointmentData.confirmedAt) &&
              selectedMedspaLocation?.appointmentSmsConfirmationRequired && (
              <Box paddingBottom="0.8rem">
                <EHRCheckboxWithText
                  name="confirm"
                  control={control}
                  dataCy="label-confirm-appointment-by-sms"
                  label="Confirm appointment by SMS"
                  checked={!watchConfirm}
                  onChange={() => setValue('confirm', !watchConfirm)}
                  text={`If this is selected, an SMS will be sent to the patient asking them to confirm the appointment.
                    The appointment status will remain Pending Confirmation until the patient responds.`}
                />
              </Box>
            )}
            <Box>
              <Box>
                <EHRTypography
                  dataCy="label-for-notes"
                  id="labelNotes"
                  variant="label"
                  style={{ marginBottom: '0.5rem' }}
                >
                  Notes (optional)
                </EHRTypography>
                <TextareaAutosize
                  data-cy="input-notes"
                  name="Notes"
                  style={{ width: '100%', height: '200px', resize: 'none', fontFamily: 'inherit' }}
                  onChange={(e) => {
                    setValue('notes', e.target.value as string);
                  }}
                  disabled={isMutating}
                  value={notes}
                />
                <FormHelperText error> </FormHelperText>
              </Box>
            </Box>

            <Divider
              style={{
                position: 'relative',
                left: '-2rem',
                width: 'calc(100% + 4rem)',
              }}
            />
            {!isNewAppointment && (
              <>
                <h3>Client Details</h3>
                <Box>
                  <EHRTypography dataCy="label-patient-name" variant="label">
                    {`${selectedPatient?.name}`}
                  </EHRTypography>
                </Box>
              </>
            )}
            {isNewAppointment && (
              <>
                <h3>Client Details</h3>

                <Box>
                  <EHRTypography dataCy="label-existing-patient" variant="label" style={{ marginBottom: '0.5rem' }}>
                    Existing Patient:
                  </EHRTypography>
                  <Box display="flex" gridGap="1rem">
                    <Box flex={1}>
                      <EHRButton
                        dataCy="btn-existingPatient"
                        type="button"
                        color={existingPatient ? 'primary' : 'default'}
                        onClick={() => {
                          setExistingPatient(true);
                          setValue('customerEmail', '');
                        }}
                        text="Yes"
                        fullWidth
                      />
                    </Box>

                    <Box flex={1}>
                      <EHRButton
                        dataCy="btn-nonExistingPatient"
                        type="button"
                        color={existingPatient ? 'default' : 'primary'}
                        onClick={() => {
                          setExistingPatient(false);
                          setValue('customerEmail', '');
                        }}
                        text="No"
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <FormHelperText error> </FormHelperText>
                </Box>

                {existingPatient ? (
                  <>
                    <Box>
                      <EHRAutocompleteSelect
                        dataCy="select-patient"
                        fullWidth
                        openOnFocus
                        disableClearable
                        clearOnEscape
                        onChange={(selectedOption: IAutocompleteSelectOption | null) => {
                          const retrievedPatient = emailOptions.find(
                            (emailOption) => emailOption.value === selectedOption?.value
                          );
                          if (!retrievedPatient) {
                            return;
                          }
                          setValue('customerEmail', retrievedPatient.value);
                        }}
                        label="Patient"
                        selectedOption={selectedPatient}
                        options={emailOptions}
                        renderInput={(params: any) => (
                          <TextField {...params} label="Search Patient" variant="outlined" />
                        )}
                        getOptionLabel={(option: IAutocompleteSelectOption) => `${option.name}`}
                        error={!!errors?.customerEmail}
                        helperText={errors.customerEmail?.message || ' '}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box display="flex" flexDirection="row" gridGap="1rem">
                      <Box flex={1}>
                        <EHRTextField
                          dataCy="input-patientFirstName"
                          id="patientFirstName"
                          label="First Name"
                          onChange={(e) => setValue('customerFirstName', e.target.value)}
                          error={!!errors.customerFirstName}
                          helperText={errors.customerFirstName?.message || ' '}
                          disabled={isMutating}
                        />
                      </Box>

                      <Box flex={1}>
                        <EHRTextField
                          dataCy="input-patientLastName"
                          id="patientLastName"
                          label="Last Name"
                          onChange={(e) => setValue('customerLastName', e.target.value)}
                          error={!!errors.customerLastName}
                          helperText={errors.customerLastName?.message || ' '}
                          disabled={isMutating}
                        />
                      </Box>
                    </Box>

                    <Box>
                      <EHRTextField
                        dataCy="input-patientEmail"
                        id="patientEmail"
                        label="Email"
                        onChange={(e) => setValue('customerEmail', e.target.value)}
                        error={!!errors.customerEmail}
                        helperText={errors.customerEmail?.message || ' '}
                        disabled={isMutating}
                      />
                    </Box>

                    <Box>
                      <Box style={{ color: '#706F6F', fontSize: '0.75rem', marginBottom: '0.5rem' }}>Phone</Box>
                      <PhoneNumberCustom
                        className={classes.phoneNumberInput}
                        dataCy="input-patientPhoneNumber"
                        id="patientPhone"
                        onChange={(e) => setValue('customerPhone', e.target.value)}
                        disabled={isMutating}
                        error={!!errors.customerPhone}
                        helperText={errors.customerPhone?.message || ' '}
                      />
                    </Box>

                    <Box>
                      <h3>Address</h3>
                    </Box>

                    <Box display="flex" flexDirection="row" gridGap="1rem">
                      <Box flex={1}>
                        <EHRTextField
                          dataCy="input-patientAddress"
                          id="patientAddress"
                          label="Address"
                          onChange={(e) => setValue('customerAddressAddressLine', e.target.value)}
                          disabled={isMutating}
                          error={!!errors.customerAddressAddressLine}
                          helperText={errors.customerAddressAddressLine?.message || ' '}
                        />
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="row" gridGap="1rem">
                      <Box flex={1}>
                        <EHRSelect
                          label="State"
                          dataCy="select-patientState"
                          onChange={(selectedState: string | number) =>
                            setValue('customerAddressState', selectedState as string)
                          }
                          id="patientState"
                          fullWidth
                          displayEmpty
                          error={!!errors.customerAddressState}
                          helperText={errors.customerAddressState?.message || ' '}
                          disabled={isMutating}
                        >
                          {states.map(({ code, name }) => (
                            <MenuItem key={code} value={code}>
                              {name}
                            </MenuItem>
                          ))}
                        </EHRSelect>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="row" gridGap="1rem">
                      <Box flex={0.5}>
                        <EHRTextField
                          dataCy="input-patientCity"
                          id="patientCity"
                          label="City"
                          onChange={(e) => setValue('customerAddressCity', e.target.value)}
                          disabled={isMutating}
                          error={!!errors.customerAddressCity}
                          helperText={errors.customerAddressCity?.message || ' '}
                        />
                      </Box>

                      <Box flex={0.5}>
                        <EHRTextField
                          dataCy="input-patientZipCode"
                          id="patientZipCode"
                          label="ZIP Code"
                          onChange={(e) => setValue('customerAddressZipCode', e.target.value)}
                          error={!!errors.customerAddressZipCode}
                          helperText={errors.customerAddressZipCode?.message || ' '}
                          disabled={isMutating}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </EHRCRUDModal.Body>
        <EHRCRUDModal.Footer dataCy="modal-edit-appointment-modal-footer">
          <EHRButton
            dataCy="btn-schedule"
            color="primary"
            text={isNewAppointment ? 'Schedule Appointment' : 'Reschedule Appointment'}
            onClick={handleSubmit(onSubmit)}
            fullWidth
            disabled={isLoading || isMutating}
          />
        </EHRCRUDModal.Footer>
      </EHRCRUDModal>
      <EHRModal
        dataCy="appointment-conflict-modal"
        open={!!conflictsModal}
        onClose={handleCloseConflictModal}
        className={classes.conflictModal}
      >
        <EHRModal.Body dataCy="appointment-conflict-modal-body">
          <Box display="flex" flexDirection="column">
            <p>
              {`You are attempting to ${isNewAppointment ? 'book' : 'reschedule'} an appointment at ${
                conflictDetails?.time
              } with `}
              {`${conflictDetails?.providerName}.`}
            </p>
            <p>{conflictsModal}.</p>
            <p>Do you want to proceed?</p>
          </Box>
        </EHRModal.Body>
        <EHRModal.Footer dataCy="appointment-conflict-modal-footer">
          <Box className={classes.conflictModalButtons}>
            <EHRButton dataCy="btn-cancel-conflict" color="default" onClick={handleCloseConflictModal} text="Cancel" />
            <EHRButton
              dataCy="btn-confirm-conflict"
              color="primary"
              onClick={() => {
                handleSubmit((data, state) => onSubmit(data, state, true))();
              }}
              disabled={isMutating}
              text="Confirm"
            />
          </Box>
        </EHRModal.Footer>
      </EHRModal>
    </>
  );
};

export default AppointmentCRUDModal;
