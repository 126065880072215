import React from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';

const SaveCardFormSuccess = ({ onClose }: { onClose?: () => void }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Typography>Payment Method Saved</Typography>
    <Box margin="15px auto 20px auto" fontSize={88}>
      <CheckCircleIcon fontSize="inherit" htmlColor="rgb(246,147,12)" />
    </Box>
    {onClose && (
      <Button variant="outlined" color="primary" onClick={onClose} data-cy="stripe-card-form-close-button">
        Close
      </Button>
    )}
  </Box>
);

export default SaveCardFormSuccess;
