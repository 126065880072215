import moment from 'moment';
import 'moment-timezone';
import { INPUT_DATE_FORMAT, PACIFIC_TZ } from 'src/constants/inventory.constants';
import { IDeliveryBlackoutDates } from 'src/interfaces/IInventoryOrder';

type Props = {
  date: moment.Moment;
  blackoutDates?: string[];
  isLegacy?: boolean;
};

const isOutsideLegacyRange = (date: moment.Moment, today: moment.Moment): boolean => {
  const followingTuesday = today.clone().startOf('isoWeek').add(1, 'week').add(1, 'day');

  const followingFriday = followingTuesday.clone().add(3, 'days');

  return date.isBefore(followingTuesday, 'day') || date.isAfter(followingFriday, 'day');
};

const isFartherThanAWeek = (date: moment.Moment, now: moment.Moment): boolean =>
  date.isAfter(now.clone().add(7, 'days'), 'day');

const isWeekendOrMonday = (date: moment.Moment): boolean => [0, 1, 6].indexOf(date.day()) > -1;

const sameReadableDate = (date: moment.Moment, now: moment.Moment): boolean =>
  date.year() === now.year() && date.dayOfYear() === now.dayOfYear();

const isBadNextDay = (date: moment.Moment, now: moment.Moment): boolean =>
  date.isSame(now.clone().add(1, 'day'), 'day') &&
  now.hours() >= 12 &&
  sameReadableDate(date, now.clone().add(1, 'day'));

const isSameDay = (date: moment.Moment, now: moment.Moment): boolean => date.isSame(now, 'day');

export const invalidDeliveryDate = ({ date, blackoutDates = [], isLegacy = false }: Props): boolean => {
  if (!date) {
    return true;
  }

  const now = moment().tz(PACIFIC_TZ);

  return (
    isWeekendOrMonday(date) ||
    isSameDay(date, now) ||
    isBadNextDay(date, now) ||
    blackoutDates.indexOf(date.clone().utc().format(INPUT_DATE_FORMAT)) > -1 ||
    (isLegacy ? isOutsideLegacyRange(date, now) : isFartherThanAWeek(date, now))
  );
};

export const formatBlackoutDates = (blackoutDates?: IDeliveryBlackoutDates[]): string[] => {
  if (!blackoutDates) {
    return [];
  }
  return blackoutDates.map(({ blackoutDate }) => moment.utc(blackoutDate).format(INPUT_DATE_FORMAT));
};
