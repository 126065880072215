import React, { useState } from 'react';
import { Box, Button, CircularProgress, LinearProgress, Typography } from '@material-ui/core';
import { usePaymentElement } from 'src/hooks/useStripe';
import { useSavePaymentMethod, useSetupIntent } from 'src/hooks/queries/usePayments';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import {
  DEFAULT_BUTTON_TEXT,
  ERROR_INITIALIZATION,
  ERROR_CREATE_PAYMENT_METHOD,
  ERROR_SAVE_PAYMENT_METHOD,
  ERROR_LOAD_PAYMENT_FORM,
  DEFAULT_BUTTON_ERROR,
  DEFAULT_ADDRESS_OPTIONS,
} from 'src/constants/stripe.constants';
import { SaveCardFormProps } from 'src/types/Stripe';
import SaveCardFormSuccess from './SaveCardFormSuccess';

const SaveCardForm = ({
  customerId,
  forMedspa,
  useClientSecret,
  showSuccess = true,
  disabled = false,
  title,
  buttonText,
  appearance,
  cardholderName,
  billingAddress = {},
  onSave,
  onClose,
  onError,
}: SaveCardFormProps) => {
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { userGroupId, isLoading: isLoadingAuth } = useSelector(({ auth }: RootState) => auth);

  const hasInvalidParams = forMedspa && !!customerId;
  const hasIdentity = !hasInvalidParams && (!!customerId || (forMedspa && !!userGroupId));

  const identity = customerId ? { customerId } : { userGroupId: userGroupId ?? 0 };

  const { mutateAsync: savePaymentMethod } = useSavePaymentMethod(identity);

  const {
    data: { merchantAccountId: stripeAccount, externalPaymentId, clientSecret } = {},
    isLoading: isLoadingSetupIntent,
    error: setupIntentError,
  } = useSetupIntent({ ...identity, disabled: !hasIdentity });

  const hasPaymentParams =
    !!externalPaymentId && (!useClientSecret || !!clientSecret) && (!customerId || !!stripeAccount);

  const {
    stripe,
    elements,
    isReady,
    isLoading: isLoadingStripe,
    error: stripeError,
    destroyPaymentElement,
  } = usePaymentElement({
    disabled: !hasPaymentParams,
    stripeAccount: customerId ? stripeAccount : undefined,
    clientSecret: useClientSecret ? clientSecret : undefined,
    elementOptions: { appearance },
  });

  const createPaymentMethod = async () => {
    if (!stripe || !elements) {
      return undefined;
    }

    const address = { ...DEFAULT_ADDRESS_OPTIONS, ...billingAddress };
    const name = cardholderName ? { name: cardholderName } : {};

    const { error, paymentMethod: { id } = {} } = await stripe.createPaymentMethod({
      elements,
      params: { billing_details: { address, ...name } },
    });

    if (error) {
      throw error;
    }

    return id?.toString();
  };

  const handleSuccess = (paymentMethodId: string) => {
    destroyPaymentElement();
    setIsSuccess(true);
    onSave?.(paymentMethodId);
  };

  const processPaymentMethod = async (setupIntentId: string) => {
    const paymentMethodId = await createPaymentMethod();
    if (!paymentMethodId) {
      throw new Error(ERROR_CREATE_PAYMENT_METHOD);
    }

    await savePaymentMethod({ setupIntentId, paymentMethodId });
    handleSuccess(paymentMethodId);
  };

  const handleSubmit = async () => {
    if (!stripe || !elements || !externalPaymentId) {
      setPaymentError(ERROR_INITIALIZATION);
      return;
    }

    setPaymentError(null);
    setIsSubmitting(true);

    try {
      elements.submit();
      await processPaymentMethod(externalPaymentId);
    } catch (error) {
      setPaymentError((error as Error)?.message ?? ERROR_SAVE_PAYMENT_METHOD);
      onError?.(error as Error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const blockingError = stripeError || (setupIntentError as Error)?.message;

  const isLoading = isLoadingSetupIntent || isLoadingStripe || isSubmitting || !isReady;

  const active = !blockingError && !isSuccess;
  const enabled = !disabled && active && hasPaymentParams && !isLoading && !!elements;

  const errorMessage = paymentError || blockingError;
  const buttonErrorMessage = blockingError ? DEFAULT_BUTTON_ERROR : undefined;
  const buttonMessage = isSuccess ? 'Payment Method Saved' : buttonErrorMessage ?? buttonText ?? DEFAULT_BUTTON_TEXT;

  const placeholder =
    !customerId && forMedspa && isLoadingAuth ? (
      <LinearProgress />
    ) : (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h6">{ERROR_LOAD_PAYMENT_FORM}</Typography>
      </Box>
    );

  return (
    <Box width="100%" data-cy="stripe-card-form">
      {!hasIdentity ? (
        placeholder
      ) : (
        <Box mb={4} p={3}>
          {showSuccess && isSuccess ? (
            <SaveCardFormSuccess onClose={onClose} />
          ) : (
            <>
              {title && (
                <Box mb={2}>
                  <h3>{title}</h3>
                </Box>
              )}
              <Box mb={2}>
                <div id="payment-element" />
              </Box>
              {errorMessage && (
                <Box mb={2} color="error.main">
                  {errorMessage}
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!enabled}
                data-cy="stripe-card-form-submit-button"
                fullWidth
              >
                {active && isLoading ? <CircularProgress size={22} /> : buttonMessage}
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SaveCardForm;
