import Api from './Api';

interface IMerchantOnboardingParams {
  country: string;
}

const MerchantOnboarding = {
  async createMerchantOnboarding(params: IMerchantOnboardingParams): Promise<{ meta: { onboardingUrl: string } }> {
    const response = await Api.post('/payments/merchants', {
      country: params.country,
    });

    return response;
  },
  async getRefreshUrl(): Promise<{ onboardingUrl: string }> {
    const response = await Api.get('/payments/merchants/refresh_url');
    return response;
  },
};

export default MerchantOnboarding;
