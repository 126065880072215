import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CardContent } from '@material-ui/core';
import { RootState, dispatch } from '../rematch/index';
import {
  ADMIN_ROLE,
  MEDSPA_ADMIN_ROLE,
  PRACTITIONER_ROLE,
  STRIPE_PAYMENT_PROCESSOR,
} from '../constants/general.constants';
import Wrapper from '../components/common/Wrapper';
import Header from '../components/PatientProfile/Header';
import { Card } from '../components/common/card';
import MainSection from '../components/PatientProfile/MainSection/MainSection';
import DiagnosesServices from '../components/PatientProfile/Services';
import ConsentHistory from '../components/PatientProfile/ConsentHistory';
import { PhotoContainer } from '../components/PatientProfile/PhotoContainer';
import HistoricalPhotoContainer from '../components/PatientProfile/HistoricalPhotoContainer';
import ActivityLogContainer from '../components/PatientProfile/ActivityLogContainer';
import PhotoRequest from '../components/PatientProfile/PhotoRequest';
import ReferralsCredits from '../components/PatientProfile/Referrals/ReferralsCredits';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import ServiceCredits from '../components/PatientProfile/ServiceCredits';
// import LoyaltyPrograms from '../components/PatientProfile/LoyaltyPrograms';
import AssignedPractitioner from '../components/PatientProfile/AssignedPractitioner';
import PractitionersNotes from '../components/PatientProfile/MainSection/Notes/PractitionersNotes';
import NoViewedStickyNotes from '../components/PatientProfile/MainSection/Notes/NoViewedStickyNotes';
import StickyNotes from '../components/PatientProfile/MainSection/Notes/StickyNotes';
import DoctorNotes from '../components/PatientProfile/MainSection/Notes/DoctorNotes';
import { useCustomCustomerNotes } from '../hooks/queries/useCustomCustomerNotes';
import { useCustomContactInfo } from '../hooks/queries/useCustomContactInfo';
import { hasAccessTo, hasFeatureEnabled } from '../utils/auth.utils';
import { GFE_REQUEST, READ_BEAUTIBANK, READ_DOCTOR_ADMIN_NOTE, READ_REFERRAL } from '../constants/actions.constants';
import { FEATURE_BEAUTIBANK } from '../constants/features.constants';
import NextAppointment from '../components/PatientProfile/NextAppointment';
import BeautyBankSection from '../components/PatientProfile/BeautyBankSection/BeautyBankSection';
import { PRACTITIONER_HOME_PATH } from '../routes/practitionerRoutes';
import { ExternalPhysicianNotes } from '../components/PatientProfile/MainSection/Notes/ExternalPhysicianNotes';
import MedspaAssignedPractitioner from '../components/PatientProfile/MedspaAssignedPractitioner';

const PractitionerAdminPatientProfile = ({
  match: {
    params: { patientId },
  },
}: {
  match: {
    params: {
      patientId: string;
    };
  };
}) => {
  const history = useHistory();
  const { newState }: any = history.location.state || '';
  const { userType, userId, featureList, permissions, roleName } = useSelector(({ auth }: RootState) => auth);
  const isMedspaAdmin = userType === ADMIN_ROLE && roleName === MEDSPA_ADMIN_ROLE;
  const { showMasqueradeBar } = useSelector(({ masquerade }: RootState) => masquerade);
  const { data: contactInfo } = useCustomContactInfo(patientId.toString());
  const { isLoading: isLoadingStickyNotes, data: customerNotesCollection = [] } = useCustomCustomerNotes(+patientId);

  const patientInfo = useSelector(({ patient }: RootState) => patient);

  const {
    basicInfo: { paymentProcessor: patientPaymentProcessor },
  } = patientInfo;

  useEffect(() => {
    if (permissions?.length > 0) {
      dispatch.patient.fetchBasicInfo({ patientId: +patientId });
      dispatch.patient.fetchContactInfo(+patientId);
      dispatch.patient.fetchMedicalProfile(+patientId);
      dispatch.patient.fetchCustomerConsents(+patientId);
      dispatch.patient.fetchPatientsNotes(+patientId);
      if (hasAccessTo(GFE_REQUEST, permissions)) {
        dispatch.patient.fetchCustomerDiagnoses(+patientId);
      }
      dispatch.patient.fetchPhotos(+patientId);
      dispatch.patient.fetchServiceVisits(+patientId);

      // Load practitioners only if you are admninistrator
      if (userType === ADMIN_ROLE) {
        // this is used for the photo request
        dispatch.patient.fetchMessagesThreads(+patientId);
      }
      dispatch.patient.fetchServiceGroups(+patientId);
    }

    return () => {
      dispatch.patient.resetPatientProfile();
    };
  }, [patientId, permissions]);

  const navigate = () => {
    history.push(PRACTITIONER_HOME_PATH);
  };

  const handleShowConversations = () => {
    history.push(`/patient/${patientId}/messaging`);
  };

  const PatientProfileComponent = (
    <main style={{ width: '100%' }}>
      {userType === PRACTITIONER_ROLE && <NavigationBar title="Patient Profile" onBackButtonClick={navigate} />}

      <article style={{ padding: '12px', width: 'inherit', backgroundColor: '#e3e3e3' }}>
        <Header
          patientId={patientId}
          handleShowConversations={handleShowConversations}
          newState={newState}
          isMedspaAdmin={isMedspaAdmin}
        />

        <PhotoRequest />
        {userType === ADMIN_ROLE && !isMedspaAdmin && <AssignedPractitioner />}
        {userType === ADMIN_ROLE && isMedspaAdmin && <MedspaAssignedPractitioner />}

        {patientId && <NextAppointment patientId={patientId} />}

        {patientId &&
          patientPaymentProcessor !== STRIPE_PAYMENT_PROCESSOR &&
          ((hasFeatureEnabled(FEATURE_BEAUTIBANK, featureList) && hasAccessTo(READ_BEAUTIBANK, permissions)) ||
            (userType === ADMIN_ROLE && contactInfo?.practitioner?.hasBeautibankEnabled)) && (
          <BeautyBankSection patientId={patientId} isMedspaAdmin={isMedspaAdmin} />
        )}

        <MainSection isMedspaAdmin={isMedspaAdmin} />
        {userType === PRACTITIONER_ROLE && (
          <>
            {hasAccessTo(READ_DOCTOR_ADMIN_NOTE, permissions) && (
              <DoctorNotes isLoading={isLoadingStickyNotes} customerNotesCollection={customerNotesCollection} />
            )}
            <ExternalPhysicianNotes patientId={patientId} practitionerId={userId} />
            <NoViewedStickyNotes patientId={patientId} customerNotesCollection={customerNotesCollection} />
          </>
        )}

        {userType === ADMIN_ROLE && (
          <Card>
            <CardContent>
              <StickyNotes
                isAdmin
                patientId={patientId}
                customerNotesCollection={customerNotesCollection}
                isLoading={isLoadingStickyNotes}
              />
            </CardContent>
          </Card>
        )}

        <PractitionersNotes />
        <ServiceCredits patientId={patientId} permissions={permissions} />
        {/* <LoyaltyPrograms /> */}
        {Boolean(permissions?.length) && hasAccessTo(READ_REFERRAL, permissions) && !isMedspaAdmin && (
          <ReferralsCredits isAdmin={userType === ADMIN_ROLE} />
        )}
        <DiagnosesServices />
        <ConsentHistory />
        <PhotoContainer />
        <HistoricalPhotoContainer patientId={patientId} showUnseenPhotosNotification />
        <ActivityLogContainer patientId={patientId} />
      </article>
    </main>
  );

  if ((userType === ADMIN_ROLE && !showMasqueradeBar) || isMedspaAdmin) {
    return <Wrapper>{PatientProfileComponent}</Wrapper>;
  }
  return PatientProfileComponent;
};

export default PractitionerAdminPatientProfile;
