import React from 'react';
import SquareCardForm, {
  CreditCardFormProps as SquareCardFormProps,
} from 'src/pages/PatientCreditCards/components/CreditCardForm';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { useStyles } from 'src/pages/PatientCreditCards/components/CreditCardForm.styles';
import { SaveCardFormProps } from 'src/types/Stripe';
import StripeCardForm from './Stripe/SaveCardForm';

export const SavePaymentMethodCustomer = (squareProps: SquareCardFormProps) => {
  const classes = useStyles();
  const { isLoading, paymentProcessor, roleName } = useSelector(({ auth }: RootState) => auth);
  const { patientId, loading, buttonText, onCardAdded } = squareProps;

  const nonLegacy = roleName !== PORTRAIT_LEGACY && roleName !== PORTRAIT_LEGACY_PLUS;

  const stripeProps: SaveCardFormProps = {
    buttonText,
    customerId: +patientId,
    disabled: loading,
    onSave: (paymentMethodId) => onCardAdded(paymentMethodId, () => {}),
  };

  if (isLoading) {
    return null;
  }

  return nonLegacy && paymentProcessor === STRIPE_PAYMENT_PROCESSOR ? (
    <div className="w-full mb-12">
      <div className={`flex ${classes.creditCardForm}`}>
        <StripeCardForm {...stripeProps} />
      </div>
    </div>
  ) : (
    <SquareCardForm {...squareProps} />
  );
};

export default SavePaymentMethodCustomer;
