import {
  loadStripe,
  StripeElementsOptionsClientSecret,
  StripeElementsOptionsMode,
  StripePaymentElement,
} from '@stripe/stripe-js';
import {
  StripeState,
  StripeElementsState,
  GetStripeOptions,
  GetStripeElementsOptions,
  GetStripePaymentElementOptions,
} from 'src/types/Stripe';
import { useState, useEffect, useRef, useMemo } from 'react';
import {
  DEFAULT_ELEMENT_OPTIONS,
  DEFAULT_ELEMENT_OPTIONS_MODE,
  DEFAULT_PAYMENT_ELEMENT_OPTIONS,
  ERROR_ELEMENTS,
  ERROR_PAYMENT_ELEMENT,
  ERROR_UNKNOWN,
  ERROR_PREFIX,
  ERROR_STRIPE_KEY,
  DEFAULT_ELEMENTS_STATE,
} from 'src/constants/stripe.constants';

export const getStripe = async ({ raiseStripeKeyError = false, ...stripeOptions }: GetStripeOptions = {}) => {
  const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  if (!stripeKey) {
    if (raiseStripeKeyError) {
      throw new Error(ERROR_STRIPE_KEY);
    }
    return null;
  }

  return loadStripe(stripeKey, stripeOptions);
};

export const useStripe = ({ disabled = false, ...stripeOptions }: GetStripeOptions & { disabled?: boolean } = {}) => {
  const [state, setState] = useState<StripeState>({
    stripe: null,
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    const loadStripeInstance = async () => {
      try {
        const stripe = await getStripe({ ...stripeOptions, raiseStripeKeyError: true });

        setState({
          stripe,
          isLoading: false,
          error: null,
        });
      } catch (error: unknown) {
        setState({
          stripe: null,
          isLoading: false,
          error: `${ERROR_PREFIX} ${error instanceof Error ? error.message : ERROR_UNKNOWN}`,
        });
      }
    };

    if (!disabled) {
      loadStripeInstance();
    }
  }, [disabled, stripeOptions.stripeAccount]);

  return state;
};

export const useStripeElements = ({
  elementOptions = {},
  ...stripeOptions
}: GetStripeElementsOptions & { disabled?: boolean }) => {
  const [state, setState] = useState<StripeElementsState>(DEFAULT_ELEMENTS_STATE);

  const memoizedElementOptions = useMemo(
    () => elementOptions,
    [elementOptions.appearance, elementOptions.clientSecret]
  );
  const memoizedStripeOptions = useMemo(() => stripeOptions, [stripeOptions.stripeAccount, stripeOptions.disabled]);

  const { stripe, error } = useStripe(memoizedStripeOptions);

  useEffect(() => setState(DEFAULT_ELEMENTS_STATE), [memoizedStripeOptions, memoizedElementOptions]);

  useEffect(() => {
    if (stripe && !state.stripe) {
      try {
        setState({
          stripe,
          error: null,
          isLoading: false,
          elements: memoizedElementOptions.clientSecret
            ? stripe?.elements(memoizedElementOptions) ?? null
            : stripe?.elements(memoizedElementOptions as StripeElementsOptionsMode) ?? null,
        });
      } catch (elementsError) {
        setState({
          stripe,
          error: elementsError instanceof Error ? elementsError.message : ERROR_ELEMENTS,
          isLoading: false,
          elements: null,
        });
      }
    } else if (error && !state.error) {
      setState({ ...DEFAULT_ELEMENTS_STATE, stripe, error, isLoading: false });
    }
  }, [stripe, error, memoizedElementOptions]);

  return state;
};

export const usePaymentElement = ({
  clientSecret,
  elementOptions = {},
  paymentElementOptions = {},
  disabled = false,
  ...stripeOptions
}: GetStripePaymentElementOptions) => {
  const [error, setError] = useState<string | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isDestroyed, setIsDestroyed] = useState(false);

  const paymentElementRef = useRef<StripePaymentElement | null>(null);

  const memoizedElementOptions = useMemo(
    () =>
      clientSecret
        ? ({
          ...DEFAULT_ELEMENT_OPTIONS,
          ...elementOptions,
          clientSecret,
        } as StripeElementsOptionsClientSecret)
        : ({
          ...DEFAULT_ELEMENT_OPTIONS_MODE,
          ...elementOptions,
        } as StripeElementsOptionsMode),
    [elementOptions.appearance, clientSecret, disabled]
  );

  const stripeState = useStripeElements({
    elementOptions: memoizedElementOptions,
    disabled,
    ...stripeOptions,
  });

  const resetPaymentElement = () => {
    if (paymentElementRef.current) {
      paymentElementRef.current.unmount();
      paymentElementRef.current.destroy();
      paymentElementRef.current = null;
      setIsReady(false);
    }
  };

  const destroyPaymentElement = () => {
    setIsDestroyed(true);
    resetPaymentElement();
  };

  useEffect(() => () => resetPaymentElement(), [stripeState.elements]);

  useEffect(() => {
    if (!isDestroyed && !paymentElementRef.current && stripeState.elements) {
      const paymentContainer = document.getElementById('payment-element');

      if (!paymentContainer) {
        setError(ERROR_PAYMENT_ELEMENT);
        return;
      }

      setError(null);

      const element = stripeState.elements.create('payment', {
        ...DEFAULT_PAYMENT_ELEMENT_OPTIONS,
        ...paymentElementOptions,
      });

      element.on('ready', () => setIsReady(true));
      element.mount('#payment-element');

      paymentElementRef.current = element;
    }
  }, [isDestroyed, paymentElementRef, stripeState.elements]);

  return {
    ...stripeState,
    isReady,
    destroyPaymentElement,
    resetPaymentElement,
    paymentElement: paymentElementRef.current,
    error: stripeState.error ?? error,
  };
};
