import { useQuery } from 'react-query';
import { MEDSPA_MERCHANT_ACCOUNT } from 'src/constants/reactQuery.keys';
import MerchantAccount from 'src/services/MerchantAccount';

export const useMerchantAccount = (userGroupId: number) =>
  useQuery({
    queryKey: [MEDSPA_MERCHANT_ACCOUNT, userGroupId],
    queryFn: () => MerchantAccount.get(userGroupId),
    enabled: !!userGroupId,
    retry: false,
  });
