import Api from './Api';

type IMerchantAccount = {
  id: number;
  providerType: string;
  onboardingStatus: string;
  chargesEnabled: boolean;
  canProcessPayments: boolean;
  externalAccountId: string;
  businessId: string;
  metadata: Record<string, any>;
};

type IMerchantAccountResponse = {
  'payments/merchantAccount': IMerchantAccount;
};

const MerchantAccount = {
  async get(userGroupId: number): Promise<IMerchantAccountResponse> {
    const response = await Api.get(`/payments/merchants/by_user_group?user_group_id=${userGroupId}`);
    return response;
  },
};

export default MerchantAccount;
