import React, { useState } from 'react';
import { Box, Modal } from '@material-ui/core';
import { useStyles } from 'src/components/DashboardAdministrator/Medspas/index.styles';
import { useQueryClient } from 'react-query';
import { EHRButton, EHRTextField } from '../../ui/v1';
import { useRegisterStripeReader } from '../../../hooks/payments/useRegisterStripeReader';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import { MEDSPA_LOCATIONS } from '../../../constants/reactQuery.keys';

export const MedspaLocationAddReaderModal = ({
  open,
  onClose,
  medspaLocationId,
}: {
  open: boolean;
  onClose: () => void;
  medspaLocationId: number;
}) => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const [registering, setRegistering] = useState<boolean>(false);
  const [registrationCode, setRegistrationCode] = useState<string>('');
  const { mutateAsync: registerReader } = useRegisterStripeReader();

  const handleRegisterReader = async () => {
    setRegistering(true);
    await registerReader(
      { registrationCode, medspaLocationId },
      {
        onSuccess: () => {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: 'Successfully registered reader!',
            },
          });
          queryClient.invalidateQueries([MEDSPA_LOCATIONS]);
          setRegistering(false);
          setRegistrationCode('');
          onClose();
        },
        onError: (e: any) => {
          const message = e.response?.data?.error;
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message:
                message ||
                compile('generic.error_message', {
                  action: 'registering',
                  element: `the reader`,
                }),
              type: 'error',
            },
          });
          setRegistering(false);
        },
      }
    );
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Box className={classes.modalContent} style={{ padding: '2rem' }}>
        <h3>Enter your Stripe reader registration code:</h3>
        <EHRTextField
          dataCy="registration-code"
          required
          fullWidth
          value={registrationCode}
          onChange={(e) => setRegistrationCode(e.target.value)}
          name="registration_code"
          label="Registration Code"
        />
        <br />
        <EHRButton
          dataCy="registration-code-submit"
          fullWidth
          color="primary"
          disabled={registering}
          onClick={handleRegisterReader}
          text={registering ? 'Registering...' : 'Register'}
        />
      </Box>
    </Modal>
  );
};
