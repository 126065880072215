import { Box, CircularProgress, Typography, withStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Square from '@square/web-sdk';
import { useAddCard } from 'src/hooks/queries/medspaAdmins/useMedspaPaymentMethods';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/rematch';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import compile from 'src/utils/toastMessagesCompiler';
import { PORTRAIT_ORDERING_SQUARE_LOCATION_ID } from 'src/constants/square.constants';
import { ClassNameMap } from 'src/types/Dom';
import { EHRButton, EHRCheckboxWithText } from 'src/components/ui/v1';
import { Cancel as ErrorIcon } from '@material-ui/icons';
import { PAYMENT_AUTHORIZATION_AGREEMENT } from 'src/constants/onboardingAgreements.constant';

export interface AddCreditCardProps extends ClassNameMap {
  closeDialog?: () => void;
  onSave?: () => void;
}

export const AddCreditCard = ({ closeDialog, onSave, classes }: AddCreditCardProps) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const paymentButtonTextRef = useRef(null);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const {
    user: { userGroupId },
    isLoading: isLoadingState,
  } = useSelector(({ auth }: RootState) => auth);

  const { mutateAsync: addCard } = useAddCard(userGroupId ?? 0);

  const applicationId = process.env.REACT_APP_SQUARE_APPLICATION_ID || '';

  const isLoading = processing || isLoadingState;

  const showError = () => {
    setIsError(true);
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'saving',
          element: 'the credit card',
        }),
        type: 'error',
      },
    });
  };

  const dismiss = () => {
    setIsError(false);
    closeDialog?.();
  };

  const focusCallback = (event: Square.SqEvent<Square.CardInputEvent>) => {
    if (event.detail.currentState.isCompletelyValid && !!paymentButtonTextRef?.current) {
      const payButton = (paymentButtonTextRef.current as HTMLSpanElement).parentElement;
      payButton?.focus();
    }
  };

  const handleSave = async ({ status, token, details }: Square.TokenResult): Promise<void> => {
    setProcessing(true);
    if (status === 'OK' && !!token) {
      const { givenName, familyName, addressLines, city, state, postalCode } = details?.billing || {};
      try {
        await addCard({
          cardNonce: token,
          cardholderName: `${givenName} ${familyName}`,
          billingAddress: {
            addressLine1: addressLines ? addressLines[0] : undefined,
            addressLine2: addressLines ? addressLines[1] : undefined,
            locality: city,
            administrativeDistrictLevel1: state,
            postalCode,
            state,
          },
        });
        onSave?.();
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: 'Card has been added successfully!',
            type: 'success',
          },
        });

        dismiss();
      } catch {
        showError();
      } finally {
        setProcessing(false);
      }
    } else {
      showError();
    }
  };

  const renderPaymentForm = () => (
    <PaymentForm
      applicationId={applicationId}
      locationId={PORTRAIT_ORDERING_SQUARE_LOCATION_ID}
      cardTokenizeResponseReceived={handleSave}
    >
      <Box marginBottom="1rem">
        <CreditCard
          includeInputLabels
          buttonProps={{
            isLoading: isLoading || !authorized,
            css: {
              background: '#12574D',
              width: '100%',
            },
          }}
          callbacks={{
            errorClassRemoved: focusCallback,
            postalCodeChanged: focusCallback,
            cardBrandChanged: focusCallback,
          }}
        >
          <span ref={paymentButtonTextRef}>{isLoading ? <CircularProgress size={20} /> : <>Add Card</>}</span>
        </CreditCard>
      </Box>
    </PaymentForm>
  );

  return (
    <Box data-testid="orderAddCard" className={classes?.paymentContainer}>
      {isError ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <ErrorIcon color="error" className={classes?.errorIcon} />
          <Typography>Unfortunately, we couldn&#39;t add this card.</Typography>
          <Typography>Please try again or add another card.</Typography>
        </Box>
      ) : (
        <>
          <Box margin="20px 0">
            <EHRCheckboxWithText
              name="confirm"
              dataCy="transactions_authorization_checkbox"
              label="Future transactions and charges authorization"
              checked={authorized}
              onChange={() => setAuthorized((prev) => !prev)}
              text={PAYMENT_AUTHORIZATION_AGREEMENT}
            />
          </Box>
          {renderPaymentForm()}
        </>
      )}
      <EHRButton
        text={isError ? 'Try Again' : 'Cancel'}
        color="default"
        dataCy="cancelNewCard"
        className={classes?.button}
        onClick={() => dismiss()}
      />
    </Box>
  );
};

export default withStyles({
  button: {
    width: '100%',
    fontSize: 16,
    margin: 'auto 0 0 0',
  },
  paymentContainer: {
    minHeight: 335,
    width: '100%',
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  errorIcon: {
    fontSize: 82,
    marginBottom: 20,
    marginTop: 10,
  },
})(AddCreditCard);
