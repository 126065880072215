import React from 'react';
import StripeCardForm from 'src/components/common/Payment/Stripe/SaveCardForm';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import AddCreditCard, {
  AddCreditCardProps,
} from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderConfirm/CardOnFile/AddCreditCard';
import { RootState } from 'src/rematch';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

export const SavePaymentMethodMedspa = (squareProps: AddCreditCardProps) => {
  const { isLoading, paymentProcessor } = useSelector(({ auth }: RootState) => auth);
  const { onSave, closeDialog: onClose } = squareProps;

  if (isLoading) {
    return null;
  }

  return paymentProcessor === STRIPE_PAYMENT_PROCESSOR ? (
    <Box minHeight={335} minWidth={335} width="100%" padding="30px" display="flex" flexDirection="column">
      <StripeCardForm onSave={onSave} onClose={onClose} forMedspa />
    </Box>
  ) : (
    <AddCreditCard {...squareProps} />
  );
};

export default SavePaymentMethodMedspa;
