import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  errorContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#E7EEED',
    padding: '0.5rem',
    borderRadius: '8px',
    color: '#FF0000',
    marginBottom: '1rem',
  },
  root: {
    width: '100%',
    padding: 0,
  },
  order: {
    padding: 0,
    paddingBottom: '4rem',
  },
  orderRoot: {
    minWidth: 275,
    padding: 10,
    fontFamily: 'Messina Sans Regular!important',
    backgroundColor: '#FFFFFF',
  },
  totalsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
    flexDirection: 'revert',
  },
  paymentOptionContainer: {
    padding: '1rem 2rem',
    border: '1px solid #C8C8C8',
    borderRadius: '8px',
    width: '49%',
    display: 'flex',
    flexDirection: 'column',
  },
  paymentSummaryContainer: {
    background: '#E7EEED',
    padding: '1rem 2rem 2rem',
    borderRadius: '8px',
    width: '49%',
  },
  summaryItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
  },
  activePill: {
    cursor: 'pointer',
    background: '#12574D',
    color: 'white',
    margin: '15px 24px 15px 0',
    padding: '5px 10px',
    '&:focus': {
      background: '#12574D',
      color: 'white',
    },
  },
  inactivePill: {
    cursor: 'pointer',
    background: '#E7EEED',
    color: '#12574D',
    border: '1px solid #12574D',
    margin: '15px 24px 15px 0',
    padding: '5px 10px',
  },
  cardName: {
    display: 'flex',
    alignItems: 'center',
  },
  cardIcon: {
    position: 'relative',
    top: '8px',
    marginLeft: '6px',
  },
  noCardsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'column',
    fontSize: '16px',
  },
  noCardButton: {
    background: '#12574D',
    color: 'white',
    borderRadius: '4px',
    padding: '0.7rem',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    marginTop: '1rem',
  },
  goToSummaryContainer: {
    textAlign: 'right',
  },
  goToSummaryButton: {
    background: '#12574D',
    color: 'white',
    borderRadius: '4px',
    padding: '0.7rem 1.5rem',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    marginTop: '1rem',
    '&:disabled': {
      backgroundColor: '#979797',
      color: 'white',
      cursor: 'default',
    },
  },
  paidContainer: {
    width: '100%',
    minHeight: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#E7EEED',
    padding: '1rem',
    borderRadius: '8px',
    flexDirection: 'column',
  },
}));
