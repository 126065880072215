import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Switch } from '@material-ui/core';
import { useMedspaRole } from 'src/hooks/queries/useMedspaAdmins';
import { useSelector } from 'react-redux';
import { EHRTypography } from 'src/components/ui/v1';
import useStyles from './index.styles';
import {
  MEDSPA_ADMIN_ROLE,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  SNACKBAR_ERROR,
  STRIPE_PAYMENT_PROCESSOR,
} from '../../../constants/general.constants';
import { showSnackbar } from '../../../utils/global';
import {
  FEATURE_BEAUTIBANK,
  FEATURE_ENABLE_CHARTING,
  FEATURE_MEDSPA_MARKETING,
} from '../../../constants/features.constants';
import { IUserGroup } from '../../../interfaces/IUserGroup';
import { IFeatureSetting } from '../../../interfaces/IFeatureSetting';
import { IFeature } from '../../../interfaces/IFeature';
import { useAddFeature, useFeatures, useRemoveFeature } from '../../../hooks/queries/useFeatures';
import { RootState, dispatch } from '../../../rematch';

interface MedspaConfigurationProps {
  userGroup: IUserGroup | undefined;
}

const MedspaConfigurations: React.FC<MedspaConfigurationProps> = ({ userGroup }) => {
  const classes = useStyles();
  const { roleName, paymentProcessor } = useSelector(({ auth }: RootState) => auth);

  const { data: medspaRoleName } = useMedspaRole(roleName === MEDSPA_ADMIN_ROLE);
  const isMedspaLegacyOrLegacyPlus = medspaRoleName === PORTRAIT_LEGACY || medspaRoleName === PORTRAIT_LEGACY_PLUS;

  const [medspaFields, setMedspaFields] = useState<IFeatureSetting[]>([]);
  const [medspaFeatures, setMedspaFeatures] = useState<IFeature[]>([]);

  const { data: features = [], isLoading: isLoadingFeatures } = useFeatures();
  const { mutateAsync: removeFeatureFlag, isLoading: isLoadingRemove, isError: errorRemoving } = useRemoveFeature();
  const { mutateAsync: addFeatureFlag, isLoading: isLoadingAddFeature, isError: errorUpdating } = useAddFeature();

  const hasStripe = paymentProcessor === STRIPE_PAYMENT_PROCESSOR;

  useEffect(() => {
    if (userGroup) {
      const userGroupFeatures = userGroup.features || [];

      setMedspaFields([
        {
          key: FEATURE_BEAUTIBANK,
          enabled: !hasStripe && userGroupFeatures.some((feat) => feat.key === FEATURE_BEAUTIBANK),
        },
        {
          key: FEATURE_MEDSPA_MARKETING,
          enabled: userGroupFeatures.some((feat) => feat.key === FEATURE_MEDSPA_MARKETING),
        },
        ...(!isMedspaLegacyOrLegacyPlus
          ? [
            {
              key: FEATURE_ENABLE_CHARTING,
              enabled: userGroupFeatures.some((feat) => feat.key === FEATURE_ENABLE_CHARTING),
            },
          ]
          : []),
      ]);
    }

    if (features && features.length > 0) {
      const legacyMedspaAllowedFeatures = [FEATURE_BEAUTIBANK, FEATURE_MEDSPA_MARKETING];

      const medspaAllowedFeatures = [...legacyMedspaAllowedFeatures, FEATURE_ENABLE_CHARTING];

      setMedspaFeatures(
        features.filter((feature) => {
          if (hasStripe && feature.key === FEATURE_BEAUTIBANK) {
            return false;
          }

          const medspaAdminFeatures = isMedspaLegacyOrLegacyPlus ? legacyMedspaAllowedFeatures : medspaAllowedFeatures;
          return medspaAdminFeatures.includes(feature.key);
        })
      );
    }

    if (errorRemoving || errorUpdating) {
      showSnackbar('Error updating Medspa features', SNACKBAR_ERROR);
    }
  }, [JSON.stringify(userGroup), errorRemoving, errorUpdating, features, isLoadingFeatures]);

  useEffect(() => {
    dispatch.auth.updateFeatures({ newFeatures: userGroup?.features ?? [] });
  }, [JSON.stringify(userGroup?.features)]);

  const updateUserGroupFeatures = async (key: string, newVal: boolean) => {
    if (newVal) {
      addFeatureFlag({ key, medspaId: userGroup?.id ?? 0 });
    } else {
      removeFeatureFlag({ key, medspaId: userGroup?.id ?? 0 });
    }
  };

  return (
    <Box>
      {medspaFeatures.map((feature) => (
        <Box>
          <Grid container direction="row" className={classes.parentGrid}>
            <Grid item xs={6}>
              <EHRTypography variant="readonly" dataCy={`medspa-feature-row-${feature.key}`}>
                {feature.name}
              </EHRTypography>
              <EHRTypography variant="description" dataCy={`medspa-feature-row-${feature.description}`}>
                {feature.description}
              </EHRTypography>
            </Grid>
            <Grid item xs={6} className={classes.switchGrid}>
              <Switch
                data-cy="showToggle"
                checked={medspaFields.find((field) => field.key === feature.key)?.enabled || false}
                disabled={isLoadingRemove || isLoadingAddFeature}
                onChange={(e) => updateUserGroupFeatures(feature.key, e.target.checked)}
                color="primary"
              />
            </Grid>
          </Grid>
          <Divider />
        </Box>
      ))}
    </Box>
  );
};

export default MedspaConfigurations;
